<template>
  <div class="results-slide slide-base"  data-step-name="Bewertung">
    <div class="slide-content">
      <div class="slide-inner">
        <div class="main-section">
          <div v-if="results">
            <!-- Header with Success Icon -->
            <div class="header-section success">
              <div class="success-icon">
                <i class="far fa-check-circle"></i>
              </div>
              <h1 class="title" v-html="$prt.settings.text.propertyRated"/>
            </div>

            <div class="result-container">
              <!-- Main Value Display -->
              <div class="main-value">
                <h2 v-html="computedPropertyValueIs"/>
              </div>

              <!-- Detailed Results -->
              <div class="details-grid">
                <div class="detail-item">
                  <div class="detail-label">Ermittelter durchschnittlicher Marktwert</div>
                  <div class="detail-value">{{ format(results.resultAbsolute) }}</div>
                </div>

                <div class="detail-item">
                  <div class="detail-label">Durchschnittlicher Wert pro m² Wohnfläche</div>
                  <div class="detail-value">{{ format(results.resultPerSqm) }}</div>
                </div>

                <div class="detail-item">
                  <div class="detail-label">Resultierende Wertspanne</div>
                  <div class="detail-value">{{ format(results.lowAbsolute) }} - {{ format(results.highAbsolute) }}</div>
                </div>

                <div class="detail-item">
                  <div class="detail-label">Ermittelte Wertspanne pro m²</div>
                  <div class="detail-value">{{ format(results.lowPerSqm) }} - {{ format(results.highPerSqm) }}</div>
                </div>
              </div>

              <!-- Success Message -->
              <div
                :class="['success-message', anyText($prt.settings.text.extraMessage) ? 'with-extra' : '']"
                v-html="$prt.settings.text.thanks"
              />

              <!-- Extra Message if present -->
              <div
                v-if="anyText($prt.settings.text.extraMessage)"
                class="extra-message"
                v-html="$prt.settings.text.extraMessage"
              />
            </div>
          </div>

          <!-- Error State -->
          <div v-else class="error-state">
            <div class="error-icon">
              <i class="far fa-exclamation-circle"></i>
            </div>
            <h1 class="title">{{ $prt.settings.text.notFound1 }}</h1>
            <h2 class="subtitle">{{ $prt.settings.text.notFound2 }}</h2>
          </div>
        </div>

        <div class="footer-section"></div>
      </div>
    </div>
  </div>
</template>

<script>
import commons from '@/commons'
import { mapState } from 'vuex'
import slideMixin from '@/mixins/slideMixin'

export default {
  name: 'ResultsSlide',
  mixins: [slideMixin],
  computed: {
    ...mapState(['leadResponse']),
    isTransitioning () {
      return this.$store.state.isTransitioning
    },
    results () {
      return this.leadResponse.results || this.getSampleData()
    },
    computedPropertyValueIs () {
      const priceTag = `<span class="price-tag">${this.format(this.results.resultAbsolute)}</span>`
      return this.$prt.settings.text.propertyValueIs.replace(/{{\s*WERT\s*}}/gi, priceTag)
    }
  },
  methods: {
    format (value) {
      return value ? `${commons.formatMoney(value)} €` : ''
    },
    getSampleData () {
      const currentUrl = new URL(window.location.href)
      const debug = currentUrl.searchParams.get('debug')
      const showSampleData = process.env.NODE_ENV !== 'production' && debug === '1'
      return this.$prt.debug && showSampleData
        ? {
          resultAbsolute: 1,
          resultPerSqm: 1,
          lowAbsolute: 1,
          highAbsolute: 1,
          lowPerSqm: 1,
          highPerSqm: 1
        }
        : undefined
    },
    anyText (text) {
      return typeof text === 'string' && text.length > 0
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/css/_layout.scss';

.results-slide {
  .header-section {
    text-align: center;
    margin-bottom: 2rem;

    &.success {
      .success-icon {
        font-size: 3rem;
        color: #20bf6b;
        margin-bottom: 1rem;
        animation: scaleIn 0.5s ease-out;
      }
    }

    .title {
      font-size: 2rem;
      color: var(--primary-color);
      font-weight: 600;
      margin-bottom: 0.5rem;
      line-height: 1.3;
    }
  }

  .result-container {
    background: #f8fafc;
    border-radius: 12px;
    padding: 2rem;
    margin-bottom: 1.5rem;
  }

  .main-value {
    text-align: center;
    margin-bottom: 2rem;

    .price-tag {
      background: var(--secondary-color);
      color: white;
      padding: 0.5rem 1rem;
      border-radius: 8px;
      font-size: 1.5rem;
      font-weight: 600;
      display: inline-block;
      margin: 0.5rem 0;
      animation: slideIn 0.5s ease-out;
    }
  }

  .details-grid {
    display: grid;
    gap: 1.5rem;
    margin-bottom: 2rem;
    animation: fadeIn 0.5s ease-out;

    .detail-item {
      padding: 1rem;
      background: white;
      border-radius: 8px;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
      transition: transform 0.3s ease;
      min-height: 44px;

      &:hover {
        transform: translateY(-2px);
      }

      .detail-label {
        color: #666;
        font-size: 0.9rem;
        margin-bottom: 0.5rem;
      }

      .detail-value {
        color: var(--primary-color);
        font-size: 1.1rem;
        font-weight: 600;
      }
    }
  }

  .success-message {
    text-align: center;
    background: #e8f5e9;
    color: #2e7d32;
    padding: 1rem;
    border-radius: 8px;
    margin-bottom: 1rem;
    animation: fadeIn 0.5s ease-out;

    &.with-extra {
      font-weight: 600;
    }
  }

  .extra-message {
    text-align: center;
    background: #fff8e1;
    color: #f57c00;
    padding: 1rem;
    border-radius: 8px;
    animation: fadeIn 0.5s ease-out;
  }

  .error-state {
    text-align: center;
    padding: 2rem;

    .error-icon {
      font-size: 3rem;
      color: #fc5c65;
      margin-bottom: 1rem;
      animation: scaleIn 0.5s ease-out;
    }

    .title {
      color: var(--primary-color);
      margin-bottom: 1rem;
    }

    .subtitle {
      color: #666;
    }
  }

  @keyframes scaleIn {
    from {
      transform: scale(0);
      opacity: 0;
    }
    to {
      transform: scale(1);
      opacity: 1;
    }
  }

  @keyframes slideIn {
    from {
      transform: translateY(20px);
      opacity: 0;
    }
    to {
      transform: translateY(0);
      opacity: 1;
    }
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  @media (max-width: 768px) {
    .main-section {
      padding: 1rem;
    }

    .result-container {
      padding: 1.5rem;
    }

    .main-value {
      .price-tag {
        font-size: 1.25rem;
        padding: 0.4rem 0.8rem;
      }
    }

    .details-grid {
      gap: 1rem;

      .detail-item {
        padding: 0.875rem;

        .detail-label {
          font-size: 0.85rem;
        }

        .detail-value {
          font-size: 1rem;
        }
      }
    }
  }
}
</style>
