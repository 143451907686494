<template>
  <div class="home-slide slide-base" data-iframe-size data-step-name="Start">
    <swiper
      ref="prtSwiper"
      class="prt-swiper"
      :options="swiperOption"
      :style="swiperStyles"
    >
      <PrtSlideWrapper :show-back-button="false">
        <HomeSlide/>
      </PrtSlideWrapper>
      <PrtSlideWrapper v-if="this.$prt.getSetting('askReason', true)">
        <ReasonSlide/>
      </PrtSlideWrapper>
      <PrtSlideWrapper v-if="this.$prt.getSetting('askReason', true) && collectedData.reason === 'Verkauf'">
        <ReasonWhenSlide/>
      </PrtSlideWrapper>

      <!-- Ask for Address -->
      <PrtSlideWrapper :show-back-button-mobile="false">
        <MapsSlide v-if="loadMapsSlide" :title="this.$prt.settings.text.region"/>
      </PrtSlideWrapper>

      <template v-if="collectedData.type === 'apartment'">
        <PrtSlideWrapper :show-back-button-mobile="false">
          <LivingAreaSlide anaytics-key="apartment-living-area" :title="this.$prt.settings.text.apartment.livingArea"/>
        </PrtSlideWrapper>
        <PrtSlideWrapper>
          <FloorSlide :title="this.$prt.settings.text.apartment.floor"/>
        </PrtSlideWrapper>
        <PrtSlideWrapper :show-back-button-mobile="false">
          <RoomCountSlide :title="this.$prt.settings.text.apartment.roomCount"/>
        </PrtSlideWrapper>
        <PrtSlideWrapper :show-back-button-mobile="false">
          <ConstructionYearSlide :title="this.$prt.settings.text.apartment.constructionYear"/>
        </PrtSlideWrapper>
      </template>

      <template v-if="collectedData.type === 'house'">
        <PrtSlideWrapper>
          <HouseTypeSlide :title="this.$prt.settings.text.house.houseType"/>
        </PrtSlideWrapper>
        <PrtSlideWrapper :show-back-button-mobile="false">
          <SiteAreaSlide :title="this.$prt.settings.text.house.siteArea"/>
        </PrtSlideWrapper>
        <PrtSlideWrapper :show-back-button-mobile="false">
          <LivingAreaSlide :title="this.$prt.settings.text.house.livingArea"/>
        </PrtSlideWrapper>
        <PrtSlideWrapper>
          <FloorCountSlide :title="this.$prt.settings.text.house.floor"/>
        </PrtSlideWrapper>
        <PrtSlideWrapper :show-back-button-mobile="false">
          <RoomCountSlide :title="this.$prt.settings.text.house.roomCount"/>
        </PrtSlideWrapper>
        <PrtSlideWrapper :show-back-button-mobile="false">
          <ConstructionYearSlide :title="this.$prt.settings.text.house.constructionYear"/>
        </PrtSlideWrapper>
      </template>

      <template v-if="collectedData.type === 'land'">
        <PrtSlideWrapper :show-back-button-mobile="false">
          <LandAreaSlide :title="this.$prt.settings.text.land.siteArea"/>
        </PrtSlideWrapper>
        <PrtSlideWrapper>
          <DevelopedPropertySlide :title="this.$prt.settings.text.land.developedProperty"/>
        </PrtSlideWrapper>
        <PrtSlideWrapper>
          <ConstructionPossibilities :title="this.$prt.settings.text.land.constructionPossibilities"/>
        </PrtSlideWrapper>
        <PrtSlideWrapper>
          <SiteLayoutSlide :title="this.$prt.settings.text.land.siteLayout"/>
        </PrtSlideWrapper>
      </template>

      <template v-if="this.isOneOf(collectedData.type, 'rent', 'rent_house', 'rent_apartment')">
        <PrtSlideWrapper>
          <RentTypeSlide :title="this.$prt.settings.text.rent.rentType"/>
        </PrtSlideWrapper>
        <PrtSlideWrapper :show-back-button-mobile="false">
          <LivingAreaSlide :title="this.$prt.settings.text.rent.livingArea"/>
        </PrtSlideWrapper>
        <PrtSlideWrapper :show-next-button-mobile="true">
          <RentCategorySlide :title="this.$prt.settings.text.rent.rentCategory"/>
        </PrtSlideWrapper>
        <PrtSlideWrapper :show-back-button-mobile="false">
          <ConstructionYearSlide :title="this.$prt.settings.text.rent.constructionYear"/>
        </PrtSlideWrapper>
      </template>

      <template v-if="collectedData.type === 'business'">
        <PrtSlideWrapper>
          <BusinessTypeSlide :title="this.$prt.settings.text.business.businessType"/>
        </PrtSlideWrapper>
        <PrtSlideWrapper :show-back-button-mobile="false">
          <LivingAreaSlide :title="this.$prt.settings.text.business.livingArea"/>
        </PrtSlideWrapper>
        <PrtSlideWrapper :show-back-button-mobile="false">
          <SiteAreaSlide :title="this.$prt.settings.text.business.siteArea"/>
        </PrtSlideWrapper>
        <PrtSlideWrapper :show-next-button-mobile="true">
          <BusinessIsRentSlide :title="this.$prt.settings.text.business.isRent"/>
        </PrtSlideWrapper>
        <PrtSlideWrapper :show-back-button-mobile="false">
          <ConstructionYearSlide :title="this.$prt.settings.text.business.constructionYear"/>
        </PrtSlideWrapper>
      </template>

      <template v-if="this.$prt.settings.extraSteps">
        <PrtSlideWrapper :key="index"
                         v-for="(extraStep, index) in this.$prt.settings.extraSteps.filter(ex => ex.activeTypes.includes(collectedData.type))">
          <ExtraStepSlide :index="index" :answers="extraStep.answers" :title="extraStep.title"/>
        </PrtSlideWrapper>
      </template>

      <!-- Some static slides -->
      <PrtSlideWrapper :show-back-button-mobile="false">
        <EmailSlide :title="this.$prt.settings.text.emailTitle"/>
      </PrtSlideWrapper>
      <PrtSlideWrapper :show-back-button-mobile="false">
        <PersonalDataSlide
          v-if="loadPersonalDataSlide"
          ref="personalDataSlide"
          @component-ready="onPersonalDataSlideReady"
        />
      </PrtSlideWrapper>
      <PrtSlideWrapper v-if="this.$prt.settings.extraSlide">
        <ExtraSlide/>
      </PrtSlideWrapper>
      <PrtSlideWrapper>
        <ResultsSlide v-if="this.$prt.settings.rateProperty && !this.$prt.settings.queueActive"/>
        <ResultsDeactivatedSlide v-else/>
      </PrtSlideWrapper>

      <div class="swiper-pagination" slot="pagination"></div>
    </swiper>

    <!-- Dialog -->
    <v-dialog name="dialog"/>

    <!-- Preloader Modal -->
    <modal name="loader" :clickToClose="false">
      <div class="loader-content-wrapper">
        <div class="loader-content">
          <h1 class="title">{{ loaderText }}</h1>
          <GoogleLoader/>
        </div>
      </div>
    </modal>

    <!-- Sms Modal -->
    <modal name="sms" :scrollable="false" height="auto" :adaptive="true" width="425px" :clickToClose="false">
      <div class="t-left" style="padding: 20px;">
        <h1 class="title">Bestätigen Sie Ihre Rufnummer</h1>
        <p>Geben Sie den an Ihre Rufnummer gesendeten Bestätigungscode ein</p>
        <hr/>
        <form ref="smsCodeForm" id="sms-code-data" @submit="verifySms">
          <input class="prt-form-input" v-model="smsCode" required/>
          <div style="display: flex; justify-content: space-around; margin-top: 30px;">
            <FancyButton type="next" @click="verifySms()">Bestätigen</FancyButton>
            <FancyButton type="prev" @click="$modal.hide('sms')">Abbrechen</FancyButton>
          </div>
        </form>
      </div>
    </modal>

    <modal name="smsWentWrong" :scrollable="false" height="auto" :adaptive="true" width="400px" :clickToClose="false">
      <div class="t-left" style="padding: 20px;">
        <h1 class="title">Etwas ist schief gelaufen</h1>
        <hr/>
        <div style="display: flex; justify-content: space-around; margin-top: 30px;">
          <FancyButton type="prev" @click="$modal.hide('smsWentWrong')">OK</FancyButton>
        </div>
      </div>
    </modal>

    <modal name="smsInvalid" :scrollable="false" height="auto" :adaptive="true" width="400px" :clickToClose="false">
      <div class="t-left" style="padding: 20px;">
        <h1 class="title">Bitte verwenden Sie einen gültigen Code</h1>
        <hr/>
        <div style="display: flex; justify-content: space-around; margin-top: 30px;">
          <FancyButton type="next" @click="onClickSmsInvalid">Wiederholen</FancyButton>
          <FancyButton type="prev" @click="$modal.hide('smsInvalid')">Stornieren</FancyButton>
        </div>
      </div>
    </modal>

    <!-- Privacy Modal -->
    <modal name="privacy" :scrollable="false" height="60%" width="80%" :styles="'overflow-y: scroll'">
      <div class="t-left" style="padding: 20px;">
        <h1 class="title">Datenschutz</h1>
        <hr/>
        <p class="t-left" v-html="this.$prt.settings.privacyPolicy"/>
      </div>
    </modal>

    <!-- DSGVO Cookie Modal -->
    <modal name="dsgvoCookie" :scrollable="false" height="60%" width="80%" :styles="'overflow-y: scroll'">
      <div class="t-left" style="padding: 20px;">
        <h1 class="title">Erklärung zum Datenschutz</h1>
        <hr/>
        <DsgvoPrivacyCookieContent/>
      </div>
    </modal>

  </div>
</template>

<script>
import { mapState } from 'vuex'
import HomeSlide from '@/slides/HomeSlide.vue'
import LivingAreaSlide from '@/slides/apartment/LivingAreaSlide'
import PrtSlideWrapper from '@/components/PrtSlideWrapper'
import FloorSlide from '@/slides/apartment/FloorSlide'
import MapsSlide from '@/slides/MapsSlide'
import PersonalDataSlide from '@/slides/PersonalDataSlide'
import RoomCountSlide from '@/slides/apartment/RoomCountSlide'
import ConstructionYearSlide from '@/slides/apartment/ConstructionYearSlide'
import GoogleLoader from '@/components/icons/GoogleLoader'
import ResultsSlide from '@/slides/ResultsSlide'
import ResultsDeactivatedSlide from '@/slides/ResultsDeactivatedSlide'
import ExtraSlide from '@/slides/ExtraSlide'
import ReasonSlide from '@/slides/ReasonSlide'
import HouseTypeSlide from '@/slides/house/HouseTypeSlide'
import SiteAreaSlide from '@/slides/house/SiteAreaSlide'
import FloorCountSlide from '@/slides/house/FloorCountSlide'
import LandAreaSlide from '@/slides/land/LandAreaSlide'
import DevelopedPropertySlide from '@/slides/land/DevelopedPropertySlide'
import ConstructionPossibilities from '@/slides/land/ConstructionPossibilitiesSlide'
import SiteLayoutSlide from '@/slides/land/SiteLayoutSlide'
import RentTypeSlide from '@/slides/rent/RentTypeSlide'
import RentCategorySlide from '@/slides/rent/RentCategorySlide'
import analytics from '@/stats'
import DsgvoPrivacyCookieContent from '@/slides/DsgvoPrivacyCookieContent'
import ReasonWhenSlide from '@/slides/ReasonWhenSlide'
import BusinessTypeSlide from '@/slides/business/BusinessTypeSlide'
import FancyButton from '@/components/FancyButton'
import BusinessIsRentSlide from '@/slides/business/BusinessIsRentSlide'
import commons from '@/commons'
import { cloudApiBaseUrl, cloudApiIframeKey } from '@/constants'
import EmailSlide from '@/slides/EmailSlide'
import ExtraStepSlide from '@/slides/ExtraStepSlide'

export default {
  name: 'home',
  components: {
    EmailSlide,
    BusinessIsRentSlide,
    BusinessTypeSlide,
    ReasonWhenSlide,
    DsgvoPrivacyCookieContent,
    RentCategorySlide,
    RentTypeSlide,
    SiteLayoutSlide,
    ConstructionPossibilities,
    FancyButton,
    DevelopedPropertySlide,
    LandAreaSlide,
    FloorCountSlide,
    SiteAreaSlide,
    HouseTypeSlide,
    ReasonSlide,
    ExtraSlide,
    ResultsDeactivatedSlide,
    ResultsSlide,
    GoogleLoader,
    ConstructionYearSlide,
    RoomCountSlide,
    PersonalDataSlide,
    MapsSlide,
    FloorSlide,
    PrtSlideWrapper,
    HomeSlide,
    LivingAreaSlide,
    ExtraStepSlide
  },
  data () {
    return {
      swiperOption: {
        pagination: {
          el: '.swiper-pagination',
          type: 'progressbar',
          progressbarFillClass: 'swiper-pagination-progressbar-fill',
          renderProgressbar: function (className) {
            const activeSlide = document.querySelector('.swiper-slide-active')
            if (activeSlide && (
              activeSlide.querySelector('.maps-slide') ||
              activeSlide.querySelector('.personal-data-slide')
            )) {
              return ''
            }
            return '<span class="' + className + '"></span>'
          }
        },
        allowTouchMove: false,
        autoHeight: true,
        observer: true,
        observeParents: true,
        observeSlideChildren: true, // Wichtig: hinzugefügt
        resistance: false,
        followFinger: false,
        simulateTouch: false,
        watchOverflow: true,
        longSwipesRatio: 0.1,
        touchRatio: 0,
        preventInteractionOnTransition: true,
        speed: 300,
        direction: 'horizontal',
        effect: 'slide',
        watchSlidesProgress: true,
        on: {
          init: function () {
            this.update()
            // Initiale Größe setzen
            if (window.parentIFrame) {
              requestAnimationFrame(() => {
                const height = document.querySelector('.swiper-slide-active')?.offsetHeight || 0
                window.parentIFrame.resize(height)
              })
            }
          },
          slideChange: function () {
            const activeSlide = document.querySelector('.swiper-slide-active')

            if (activeSlide) {
              const progressBar = document.querySelector('.swiper-pagination-progressbar')
              if (progressBar) {
                progressBar.style.display = activeSlide.querySelector('.maps-slide, .personal-data-slide') ? 'none' : 'block'
              }

              // Slide-spezifische Größenanpassung
              if (window.parentIFrame) {
                setTimeout(() => {
                  window.parentIFrame.resize(activeSlide.offsetHeight)
                }, 50)
              }
            }
          },
          transitionEnd: function () {
            this.update()
          }
        }
      },
      loaderText: 'Ihre Immobilie wird bewertet',
      smsCode: '',
      loadMapsSlide: false,
      loadPersonalDataSlide: true
    }
  },
  computed: {
    ...mapState(['currentSlide', 'collectedData', 'showLoader', 'twilioSId']),
    swiper () {
      return this.$refs.prtSwiper.swiper
    },
    swiperStyles () {
      return {
        width: '100%',
        height: 'auto',
        display: 'flex',
        flexDirection: 'column'
      }
    }
  },
  created () {
    // Initial setup
    this.$store.commit('setTransitioning', false)

    // Listen for window load
    window.addEventListener('load', this.handleWindowLoad)

    const urlParams = new URLSearchParams(window.location.search)
    if (urlParams.has('preselect') && commons.getAllLeadTypes().includes(urlParams.get('preselect').toUpperCase())) {
      this.collectedData.type = urlParams.get('preselect').toLowerCase()
      this.$store.commit('nextSlide')

      if (window.parentIFrame) {
        // Kleiner Timeout, damit der Slide-Wechsel und das Resize geschehen kann
        setTimeout(() => {
          window.parentIFrame.getParentProps((parentProps) => {
            // Scroll zum Anfang des iframes
            window.parentIFrame.scrollTo(0, parentProps.offsetTop)
          })
        }, 200)
      }
    }
  },
  mounted () {
    console.log('PRT Settings:', this.$prt)
    console.log('Store State:', this.$store.state)
    console.log('Collected Data:', this.$store.state.collectedData)
    this.loadMapsSlide = true
    this.loadPersonalDataSlide = true

    // Ensure initial slides are ready
    this.$nextTick(async () => {
      await this.ensureSlidesAreReady()
      if (this.$refs.prtSwiper?.swiper) {
        this.$refs.prtSwiper.swiper.update()
        this.safeIframeResize()
      }
    })
  },
  methods: {
    safeIframeResize () {
      if (window.parentIFrame && typeof window.parentIFrame.resize === 'function') {
        window.parentIFrame.resize()
      }
    },

    handleWindowLoad () {
      if (this.$refs.prtSwiper && this.$refs.prtSwiper.swiper) {
        this.$refs.prtSwiper.swiper.update()
      }
    },

    async updateSwiper () {
      if (this.$refs.prtSwiper && this.$refs.prtSwiper.swiper) {
        await this.$nextTick()
        this.$refs.prtSwiper.swiper.update()

        this.safeIframeResize()
      }
    },

    async onPersonalDataSlideReady () {
      console.log('PersonalDataSlide ready event received')
      await this.$nextTick()
      this.checkPersonalDataSlide()
    },

    checkPersonalDataSlide () {
      console.log('Checking PersonalDataSlide...', {
        hasRef: !!this.$refs.personalDataSlide,
        simulationStarted: this.$refs.personalDataSlide?.simulationStarted,
        hasStartRating: typeof this.$refs.personalDataSlide?.startRating === 'function'
      })

      if (this.$refs.personalDataSlide &&
        !this.$refs.personalDataSlide.simulationStarted &&
        typeof this.$refs.personalDataSlide.startRating === 'function') {
        const activeSlide = document.querySelector(
          '.swiper-slide-active .prt-slide [data-step-name]'
        )

        console.log('Active slide:', {
          element: activeSlide,
          stepName: activeSlide?.dataset.stepName
        })

        if (activeSlide &&
          activeSlide.dataset.stepName === 'Persönliche Daten') {
          console.log('Starting rating...')
          this.$refs.personalDataSlide.startRating()
        }
      }
    },

    async ensureSlidesAreReady () {
      console.log('Ensuring slides are ready...')
      await this.$nextTick()

      for (let i = 0; i < 3; i++) {
        await new Promise(resolve => requestAnimationFrame(resolve))
      }

      if (this.$refs.prtSwiper?.swiper) {
        const swiper = this.$refs.prtSwiper.swiper
        console.log('Current slides:', swiper.slides.length)
        swiper.update()

        // Add this check here
        if (this.$refs.personalDataSlide) {
          this.checkPersonalDataSlide()
        }

        return swiper.slides.length
      }
      return 0
    },

    isOneOf (source, ...withStrings) {
      if (typeof source === 'string') {
        const sourceUpper = source.toUpperCase()
        if (Array.isArray(withStrings)) {
          for (let i = 0; i < withStrings.length; i++) {
            if (typeof withStrings[i] === 'string' && withStrings[i].toUpperCase() === sourceUpper) {
              return true
            }
          }
        }
      }
      return false
    },

    async verifySms () {
      if (!this.$refs.smsCodeForm.checkValidity()) {
        this.$refs.smsCodeForm.reportValidity()
        return
      }

      if (this.twilioSId) {
        const body = new URLSearchParams()
        body.append('key', cloudApiIframeKey)
        body.append('smsCode', this.smsCode)
        body.append('twilioSId', this.twilioSId)
        const options = {
          credentials: 'include',
          method: 'POST',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          },
          body: body
        }
        try {
          this.$store.commit('showLoader', true)
          const resp = await fetch(`${cloudApiBaseUrl}/iframe/verifySms`, options)
          const respText = await resp.text()
          if (respText === 'VALID') {
            this.smsCode = ''
            this.$modal.hide('sms')
            // Setze den Status und fahre mit der Bewertung fort
            this.$store.commit('setSmsVerified', true)
            // Wichtig: Hier direkt die Bewertung starten
            await this.$refs.personalDataSlide.rateProperty()
          } else {
            this.smsCode = ''
            this.$modal.hide('sms')
            this.$modal.show('smsInvalid')
          }
        } catch (e) {
          console.error(e)
          this.$modal.show('smsWentWrong')
        } finally {
          this.$store.commit('showLoader', false)
        }
      }
    },

    onClickSmsInvalid () {
      this.$modal.hide('smsInvalid')
      this.$modal.show('sms')
    }
  },

  watch: {
    currentSlide: {
      immediate: true,
      async handler (newValue) {
        console.log('currentSlide watch triggered:', newValue)

        if (this.$store.state.isTransitioning) {
          console.log('Transition already in progress, skipping')
          return
        }

        this.$store.commit('setTransitioning', true)

        try {
          const slideCount = await this.ensureSlidesAreReady()
          console.log('Slides ready:', slideCount)

          if (this.$refs.prtSwiper?.swiper) {
            const swiper = this.$refs.prtSwiper.swiper

            await new Promise(resolve => setTimeout(resolve, 50))
            swiper.update()

            console.log('Before transition:', {
              slides: swiper.slides.length,
              activeIndex: swiper.activeIndex,
              newValue
            })

            await swiper.slideTo(newValue, 300)
            await new Promise(resolve => setTimeout(resolve, 300))
            swiper.update()

            console.log('After transition:', {
              slides: swiper.slides.length,
              activeIndex: swiper.activeIndex
            })

            this.safeIframeResize()

            // Add extra check for PersonalDataSlide
            if (this.$refs.personalDataSlide) {
              await this.$nextTick()
              this.checkPersonalDataSlide()
            }

            // Analytics tracking
            const activeSlide = document.querySelector('.swiper-slide-active .prt-slide [data-step-name]')
            if (activeSlide?.dataset && this.collectedData.type) {
              const stepName = activeSlide.dataset.stepName
              const type = this.collectedData.type.toLowerCase()
              const typeTranslations = {
                apartment: 'Wohnung',
                house: 'Haus',
                land: 'Grundstück',
                rent: 'Miete',
                rent_apartment: 'Miete',
                rent_house: 'Miete',
                business: 'Gewerbe'
              }

              const translation = typeTranslations[type] || type
              console.log('Tracking step:', `${translation} - ${stepName}`)
              analytics.trackStep(`${translation} - ${stepName}`)
            }
          }
        } catch (error) {
          console.error('Error during slide transition:', error)
        } finally {
          this.$store.commit('setTransitioning', false)
        }
      }
    },

    'collectedData.type': {
      async handler (newType) {
        console.log('Type changed to:', newType)
        if (newType) {
          // Wait for slides to be updated
          await this.ensureSlidesAreReady()
          this.$nextTick(() => this.updateSwiper())
        }
      }
    }
  },
  beforeDestroy () {
    window.removeEventListener('load', this.handleWindowLoad)
    if (this.resizeInterval) {
      clearInterval(this.resizeInterval)
    }
  }
}
</script>

<style lang="scss">
.home-slide {
  height: 100vh;
  width: 100%;
  position: relative;
  overflow: hidden;
}

.prt-swiper {
  height: 100%;
  width: 100%;

  .swiper-container {
    height: auto !important;
  }

  .swiper-wrapper {
    height: auto !important;
    display: flex;
  }

  .swiper-slide {
    height: auto !important;
    flex-shrink: 0;
  }

    .prt-slide-wrapper {
      width: 100%;
      height: 100%;
      position: relative;
    }
  }

// Progress bar
.swiper-pagination-progressbar {
  background: rgba(0, 0, 0, 0.1);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 2px;
  z-index: 10;

  .swiper-pagination-progressbar-fill {
    background: var(--secondary-color);
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    transform-origin: left top;
  }
}

// Specific hide for MapsSlide and PersonalDataSlide
.swiper-slide-active {
  .maps-slide, .personal-data-slide {
    & ~ .swiper-pagination {
      display: none !important;
      visibility: hidden !important;
      opacity: 0 !important;
    }
  }
}

.maps-slide, .personal-data-slide {
  & + .swiper-pagination,
  & ~ .swiper-pagination {
    display: none !important;
    visibility: hidden !important;
    opacity: 0 !important;
  }
}

.swiper-slide-active {
  .maps-slide, .personal-data-slide {
    & ~ .swiper-pagination-progressbar {
      display: none !important;
    }
  }
}

// Fix für iOS
.swiper-container-android .swiper-slide,
.swiper-wrapper {
  transform: translate3d(0, 0, 0);
}

// Loader
.loader-content-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

// Base styles
.slide-base {
  min-height: 0;
  height: auto;
}

// Modal styles
.modal-content {
  padding: 20px;

  hr {
    margin: 15px 0;
    border: 0;
    height: 1px;
    background: #ccc;
  }
}

.swiper-slide-active {
  .maps-slide, .personal-data-slide {
    & ~ .swiper-pagination-progressbar {
      display: none !important;
    }
  }
}

// SMS Modal Styles
.modal[name="sms"] {
  .t-left {
    .title {
      font-size: 1.5rem !important;
      color: var(--primary-color) !important;
      margin-bottom: 1rem !important;
    }

    p {
      color: #666 !important;
      margin-bottom: 1.5rem !important;
      font-size: 1rem !important;
    }

    hr {
      border: none !important;
      border-top: 1px solid #eee !important;
      margin: 1rem 0 !important;
    }

    .prt-form-input {
      width: 100% !important;
      padding: 1rem !important;
      border: 2px solid #e0e0e0 !important;
      border-radius: 12px !important;
      font-size: 1rem !important;
      margin-bottom: 1.5rem !important;
      transition: all 0.3s ease !important;
      min-height: 44px !important;

      &:focus {
        border-color: var(--secondary-color) !important;
        box-shadow: 0 0 0 3px rgba(23, 181, 160, 0.1) !important;
        outline: none !important;
      }
    }

    div[style="display: flex; justify-content: space-around; margin-top: 30px;"] {
      gap: 1rem !important;

      .fancy-button {
        flex: 1 !important;
        max-width: 150px !important;
      }
    }
  }
}

// Invalid SMS und Error Modal Styles
.modal[name="smsInvalid"],
.modal[name="smsWentWrong"] {
  .t-left {
    .title {
      font-size: 1.25rem !important;
      color: #dc3545 !important;
      margin-bottom: 1rem !important;
    }

    hr {
      border: none !important;
      border-top: 1px solid #eee !important;
      margin: 1rem 0 !important;
    }

    div[style="display: flex; justify-content: space-around; margin-top: 30px;"] {
      gap: 1rem !important;

      .fancy-button {
        flex: 1 !important;
        max-width: 150px !important;
      }
    }
  }
}
</style>
