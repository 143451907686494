<template>
  <swiper-slide>
    <div class="prt-slide-wrapper" ref="slideWrapper">
      <div class="prt-slide">

        <!-- Inhalt des Slides -->
        <slot/>

        <!-- Mobile-Buttons unten, wenn gewünscht -->
        <FancyButton
          v-if="showNextButtonMobile"
          @click="next"
          class="next-button-fancy"
          type="next"
        >
          Weiter
        </FancyButton>

        <FancyButton
          v-if="showBackButton && showBackButtonMobile"
          @click="prev"
          class="back-button-fancy"
          type="prev"
        >
          Zurück
        </FancyButton>
      </div>
    </div>
  </swiper-slide>
</template>

<script>
import FancyButton from '@/components/FancyButton'

export default {
  name: 'PrtSlideWrapper',
  components: { FancyButton },

  props: {
    showBackButton: {
      type: Boolean,
      default: true
    },
    showBackButtonMobile: {
      type: Boolean,
      default: true
    },
    showNextButtonMobile: {
      type: Boolean,
      default: false
    }
  },

  methods: {
    // Vereinfachte Navigation ohne zusätzliches Scroll-Handling
    prev () {
      this.$store.commit('prevSlide')
    },
    next () {
      this.$store.commit('nextSlide')
    }
  }
}
</script>

<style lang="scss">
.prt-slide-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: auto;
  padding: 20px 0;
  outline: none;
}

.prt-slide {
  position: relative;
  width: 100%;
  padding: 14px;
}

.back-button {
  position: absolute;
  left: 10px;
  top: 20px;
  background: #f0f0f0 url('data:image/svg+xml;base64,...') no-repeat center center;
  background-size: 18px 12px;
  padding: 14px;
  cursor: pointer;
  transition: all 0.1s ease-in;
  flex: 0;
  height: 15px;
  width: 15px;
  margin-right: 10px;
  z-index: 1;

  @media only screen and (max-width: 667px) {
    display: none;
  }

  &:hover {
    transform: scale(1.1);
    background-color: var(--button-prev-color);
  }
}

/* Mobile "Zurück"-Button unten */
.back-button-fancy {
  width: 100%;
  margin-top: 25px;
  padding: 0 16px;
  display: none;

  @media only screen and (max-width: 667px) {
    display: block;
  }
}

/* Mobile "Weiter"-Button unten */
.next-button-fancy {
  width: 100%;
  margin-top: 25px;
  margin-bottom: -15px;
  padding: 0 16px;
  display: none;

  @media only screen and (max-width: 667px) {
    display: block;
  }
}
</style>
