<template>
  <div class="range-wrapper">
    <div class="range-icon">
      <slot name="icon"/>
    </div>
    <div class="range-slide-section">
      <div class="range-slider">
        <VueSlider
          :value="Number(sliderValue || min)"
          @change="updateValue"
          :min="min"
          :max="max"
          :marks="computedMarks"
          :tooltip="tooltip"
          :tooltipFormatter="tooltipFormatter"
          :tooltipStyle="tooltipStyle"
          :processStyle="processStyle"
        />
      </div>
      <div class="range-footer">
        <div class="custom-input-wrapper">
          <label :for="prtKey">Alternativ eintippen</label>
          <div class="input-group">
            <input
              :id="prtKey"
              type="number"
              :value="inputValue"
              @input="updateValue"
              class="area-input"
              :min="min"
              :max="max"
            >
            <span class="unit">{{ unit }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import commons from '@/commons'

const debouncedUpdate = commons.debounce((vm, val) => {
  vm.$emit('input', Number(val))
}, 200)

export default {
  name: 'RangeSlideWrapper',
  props: {
    customMarks: {
      type: Object,
      default: () => ({})
    },
    unit: {
      type: String,
      default: ''
    },
    prtKey: {
      type: String,
      required: true
    },
    value: {
      type: Number,
      required: false,
      default: 0
    },
    min: {
      type: Number,
      required: true
    },
    max: {
      type: Number,
      required: true
    },
    labelEvery: {
      type: Number,
      required: true
    },
    showMinLabel: {
      type: Boolean,
      default: false
    },
    plusOnMax: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      sliderValue: this.value || this.min,
      inputValue: this.value || this.min,
      tooltip: 'always',
      tooltipFormatter: val => `${val}${this.unit}${val === this.max && this.plusOnMax ? '+' : ''}`,
      tooltipStyle: {
        color: '#fff',
        fontSize: '18px',
        backgroundColor: 'var(--secondary-color)',
        borderColor: 'var(--secondary-color)'
      },
      processStyle: {
        backgroundColor: 'var(--secondary-color)'
      }
    }
  },
  computed: {
    computedMarks () {
      // Wenn benutzerdefinierte Markierungen vorhanden sind, diese verwenden
      if (Object.keys(this.customMarks).length > 0) {
        const marks = {}
        Object.entries(this.customMarks).forEach(([value, label]) => {
          marks[value] = {
            label: `${label}${this.unit}${value === this.max && this.plusOnMax ? '+' : ''}`,
            labelStyle: {
              color: '#666',
              fontSize: '0.9rem'
            },
            labelActiveStyle: {
              color: 'var(--secondary-color)'
            }
          }
        })
        return marks
      }

      // Sonst die Standard-Markierungen verwenden
      const marks = {}
      for (let i = this.min; i <= this.max; i++) {
        if (i % this.labelEvery === 0 || (i === this.min && this.showMinLabel) || i === this.max) {
          marks[i] = {
            label: `${i}${this.unit}${i === this.max && this.plusOnMax ? '+' : ''}`,
            labelStyle: {
              color: '#666',
              fontSize: '0.9rem'
            },
            labelActiveStyle: {
              color: 'var(--secondary-color)'
            }
          }
        }
      }
      return marks
    }
  },
  created () {
    this.initializeValues()
  },
  methods: {
    initializeValues () {
      const val = Number(this.value || this.min)
      this.sliderValue = val
      this.inputValue = val
    },
    updateValue (e) {
      let v = e
      if (e instanceof Event) {
        v = Number(e.target.value)
      }
      if (isNaN(v) || v === null || v === undefined) {
        v = this.min
      }
      v = Math.max(this.min, Math.min(this.max, Number(v)))
      this.inputValue = v
      this.sliderValue = v
      debouncedUpdate(this, v)
    }
  },
  watch: {
    value: {
      immediate: true,
      handler (newValue) {
        const val = Number(newValue || this.min)
        this.sliderValue = val
        this.inputValue = val
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.range-wrapper {
  width: 100%;
  display: grid;
  grid-template-columns: minmax(100px, 20%) auto;
  gap: 1.5rem;

  @media screen and (max-width: 600px) {
    grid-template-columns: auto;
    grid-template-rows: auto auto;
    gap: 20px;

    .range-icon > * {
      width: 50%;
    }
  }

  .range-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;

    > * {
      width: 100%;
      max-width: 80px;
      height: auto;
      color: var(--secondary-color);
    }
  }

  .range-slide-section {
    padding: 24px 24px 8px 24px;
    background: #f8fafc;
    border-radius: 12px;

    @media only screen and (max-width: 667px) {
      padding: 0;
    }

    .range-slider {
      padding: 24px 24px 8px 24px;

      .vue-slider {
        .vue-slider-rail {
          background-color: #e0e0e0;
          border-radius: 4px;
        }

        .vue-slider-process {
          background-color: var(--secondary-color);
          border-radius: 4px;
        }

        .vue-slider-dot-handle {
          border-color: var(--secondary-color);
          background-color: var(--secondary-color);
          transition: all 0.3s ease;

          &:hover {
            transform: scale(1.2);
          }
        }

        .vue-slider-mark-label {
          font-size: 0.9rem;
          color: #666;
          margin-top: 0.5rem;
        }

        .vue-slider-dot {
          .vue-slider-dot-handle {
            cursor: pointer;
            width: 100%;
            height: 100%;
            border-radius: 50%;
            background-color: var(--secondary-color);
            border: 2px solid var(--secondary-color);
            transition: all 0.3s;

            &:hover {
              transform: scale(1.2);
            }
          }
        }

        .vue-slider-mark {
          &-label {
            font-weight: 500;
          }

          &-active {
            .vue-slider-mark-label {
              color: var(--secondary-color);
            }
          }
        }
      }
    }

    .range-footer {
      margin-top: 44px;

      .grid {
        display: grid;
        grid-template-columns: 50% 50%;
        grid-gap: 8px;

        @media only screen and (max-width: 667px) {
          display: block;

          > div {
            margin-bottom: 10px;
          }
        }
      }

      .range-back-button {
        display: none;

        @media only screen and (max-width: 667px) {
          display: block;
        }
      }
    }

    label {
      display: block;
      width: 100%;
      text-align: left;
      color: #666;
      margin-bottom: 0.5rem;
      font-size: 0.9rem;
    }

    .custom-input-wrapper {
      .input-group {
        display: flex;
        align-items: center;
        background: white;
        border: 2px solid #e0e0e0;
        border-radius: 8px;
        padding: 0.5rem 1rem;
        width: 100%;
        max-width: 300px;
        transition: border-color 0.3s ease;

        &:focus-within {
          border-color: var(--secondary-color);
        }

        .area-input {
          flex: 1;
          border: none;
          background: transparent;
          padding: 0.5rem;
          font-size: 1.1rem;
          text-align: right;
          color: var(--secondary-color);
          font-weight: 500;
          width: 100%;

          &:focus {
            outline: none;
          }

          &::-webkit-inner-spin-button,
          &::-webkit-outer-spin-button {
            -webkit-appearance: none;
            margin: 0;
          }

          &::placeholder {
            color: #999;
          }
        }

        .unit {
          font-size: 1.1rem;
          color: #666;
          font-weight: 500;
          padding-left: 0.5rem;
          white-space: nowrap;
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .range-wrapper {
    grid-template-columns: auto;
    grid-template-rows: auto auto;
    gap: 1rem;

    .range-icon > * {
      max-width: 60px;
    }

    .range-slide-section {
      padding: 1rem;

      .range-slider {
        padding: 1rem 0.5rem;
      }

      .range-footer {
        margin-top: 1.5rem;

        .grid {
          display: block;

          > div {
            margin-bottom: 10px;
          }
        }
      }
    }
  }
}
</style>
