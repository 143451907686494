<template>
  <div class="t-left all-t-left">
    <h2>Datenschutzhinweis und Einwilligung</h2>
    <p>
      {{ $prt.settings.realmName }} arbeitet für die Wohnmarktanalyse mit dem
      Partner LeadMeUp UG (haftungsbeschränkt), Fährstr. 18, 46282 Dorsten.
    </p>
    <h4>Datenschutzhinweis</h4>
    <p>
      Verantwortlich für die Datenverarbeitung ist {{ $prt.settings.realmName }},
      {{ $prt.settings.dsgvo.address }}, {{ $prt.settings.dsgvo.plz }} {{ $prt.settings.dsgvo.place }}.
      Für die Erstellung der Wohnmarktanalyse, die anschließende
      Übermittlung an Sie und die Kontaktaufnahme durch einen Immobilienexperten werden
      folgende Daten benötigt:
    </p>
    <ul>
      <li>Angaben zum Objekt (u.a. Adresse, Immobilientyp)</li>
      <li>Kontaktdaten (Anrede, Name, E-Mailadresse, Telefonnummer)</li>
    </ul>
    <p>
      Die Verarbeitung der Daten erfolgt aufgrund Ihrer Einwilligung, Rechtsgrundlage für die
      Datenverarbeitung ist Art. 6 Abs. 1 Satz 1 lit. a) DSGVO.
    </p>
    <p>
      Die Daten werden durch {{ $prt.settings.realmName }} erhoben und
      verarbeitet und an die LeadMeUp UG (haftungsbeschränkt) zur dortigen Datenverarbeitung und Nutzung
      übermittelt. Die Aufbewahrung der verarbeiteten personenbezogenen Daten richtet sich
      dabei ausschließlich nach den jeweils geltenden gesetzlichen Vorgaben.
    </p>
    <p>
      Wenn und soweit Anhaltspunkte gegeben sind, welche eine rechtsmissbräuchliche
      Inanspruchnahme der Wohnmarkanalyse indizieren, dürfen sowohl {{ $prt.settings.realmName }}
      als auch der Partner LeadMeUp UG (haftungsbeschränkt) die übermittelten Daten
      verarbeiten, nutzen und an notwendige Dritte übermitteln, um eine rechtsmissbräuchliche
      Inanspruchnahme aufzuklären und dem rechtmäßigen Dateninhaber einen ausreichenden
      Schutz seiner Daten zu ermöglichen.
    </p>
    <h2>Plugins und Tools</h2>
    <h5>Verwendung von Webfonts</h5>
    <p>
      Auf diesen Internetseiten werden externe Schriften, Google Fonts verwendet. Google Fonts
      ist ein Dienst der Google Inc. ("Google"). Die Einbindung dieser Web Fonts erfolgt durch
      einen Serveraufruf, in der Regel ein Server von Google in den USA. Hierdurch wird an den
      Server übermittelt, welche unserer Internetseiten Sie besucht haben. Auch wird die IPAdresse des Browsers
      des Endgerätes des Besuchers dieser Internetseiten von Google
      gespeichert. Nähere Informationen finden Sie in den Datenschutzhinweisen von Google, die
      Sie hier abrufen können:<br><br>
      <a href="https://www.google.com/fonts#AboutPlace:about">www.google.com/fonts#AboutPlace:about</a><br>
      <a href="https://www.google.com/policies/privacy/">www.google.com/policies/privacy/</a>
    </p>
    <h5>Google Maps</h5>
    <p>Diese Seite nutzt den Kartendienst Google Maps. Anbieter ist die Google Ireland Limited (&bdquo;Google&ldquo;),
      Gordon House, Barrow Street, Dublin 4, Irland.</p>
    <p>Zur Nutzung der Funktionen von Google Maps ist es notwendig, Ihre IP-Adresse zu speichern. Diese Informationen
      werden in der Regel an einen Server von Google in den USA übertragen und dort gespeichert. Der Anbieter dieser
      Seite hat keinen Einfluss auf diese Datenübertragung.</p>
    <p>Die Nutzung von Google Maps erfolgt im Interesse einer ansprechenden Darstellung unserer Online-Angebote und an
      einer leichten Auffindbarkeit der von uns auf der Website angegebenen Orte. Dies stellt ein berechtigtes Interesse
      im Sinne von Art. 6 Abs. 1 lit. f DSGVO dar. Sofern eine entsprechende Einwilligung abgefragt wurde, erfolgt die
      Verarbeitung ausschließlich auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO; die Einwilligung ist jederzeit
      widerrufbar.</p>
    <p>Mehr Informationen zum Umgang mit Nutzerdaten finden Sie in der Datenschutzerklärung von Google: <a
      href="https://policies.google.com/privacy?hl=de" target="_blank">https://policies.google.com/privacy?hl=de</a>.
    </p>
    <h2>Analyse-Tools und Werbung</h2>
    <h5>Facebook Pixel</h5>
    <p>
      Diese Website nutzt zur Konversionsmessung der Besucheraktions-Pixel von Facebook.
      Anbieter dieses Dienstes ist die Facebook Ireland Limited, 4 Grand Canal Square, Dublin 2,
      Irland. Die erfassten Daten werden nach Aussage von Facebook jedoch auch in die USA und
      in andere Drittländer übertragen.
    </p>
    <p>
      So kann das Verhalten der Seitenbesucher nachverfolgt werden, nachdem diese durch Klick
      auf eine Facebook-Werbeanzeige auf die Website des Anbieters weitergeleitet wurden.
      Dadurch können die Wirksamkeit der Facebook-Werbeanzeigen für statistische und
      Marktforschungszwecke ausgewertet werden und zukünftige Werbemaßnahmen optimiert
      werden.
    </p>
    <p>
      Die erhobenen Daten sind für uns als Betreiber dieser Website anonym, wir können keine
      Rückschlüsse auf die Identität der Nutzer ziehen. Die Daten werden aber von Facebook
      gespeichert und verarbeitet, sodass eine Verbindung zum jeweiligen Nutzerprofil möglich ist
      und Facebook die Daten für eigene Werbezwecke, entsprechend der <u>Facebook-Datenverwendungsrichtlinie</u>
      verwenden kann. Dadurch kann Facebook das Schalten von
      Werbeanzeigen auf Seiten von Facebook sowie außerhalb von Facebook ermöglichen. Diese
      Verwendung der Daten kann von uns als Seitenbetreiber nicht beeinflusst werden.
    </p>
    <p>
      Die Nutzung von Facebook-Pixel erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO. Der
      Websitebetreiber hat ein berechtigtes Interesse an effektiven Werbemaßnahmen unter
      Einschluss der sozialen Medien. Sofern eine entsprechende Einwilligung abgefragt wurde (z.
      B. eine Einwilligung zur Speicherung von Cookies), erfolgt die Verarbeitung ausschließlich auf
      Grundlage von Art. 6 Abs. 1 lit. a DSGVO; die Einwilligung ist jederzeit widerrufbar.
    </p>
    <p>
      In den Datenschutzhinweisen von Facebook finden Sie weitere Hinweise zum Schutz Ihrer
      Privatsphäre: <a
      href="https://de-de.facebook.com/about/privacy/">https://de-de.facebook.com/about/privacy/</a>.
    </p>
    <p>
      Sie können außerdem die Remarketing-Funktion „Custom Audiences" im Bereich
      Einstellungen für Werbeanzeigen
      unter <a href="https://www.facebook.com/ads/preferences/?entry_product=ad_settings_screen">https://www.facebook.com/ads/preferences/?entry_product=ad_settings_screen</a>
      deaktivieren. Dazu müssen Sie bei Facebook angemeldet sein.
    </p>
    <p>
      Wenn Sie kein Facebook Konto besitzen, können Sie nutzungsbasierte Werbung von
      Facebook auf der Website der European Interactive Digital Advertising Alliance
      deaktivieren:
      <a href="http://www.youronlinechoices.com/de/praferenzmanagement/">http://www.youronlinechoices.com/de/praferenzmanagement/</a>.
    </p>
    <h5>Google Tag Manager</h5>
    <p>
      Diese Webseite verwendet Google Tag Manager. Der Tag Manager erfasst keine
      personenbezogenen Daten. Das Tool sorgt für die Auslösung anderer Tags, die ihrerseits
      unter Umständen Daten erfassen. Google Tag Manager greift nicht auf diese Daten zu. Wenn
      auf Domainoder Cookie-Ebene eine Deaktivierung vorgenommen wurde, bleibt diese für alle
      Tracking-Tags bestehen, die mit Google Tag Manager implementiert werden. Die
      Datenschutzhinweise von Google zu diesem Tool finden Sie hier:
      <a
        href="https://www.google.de/tagmanager/use-policy.html">https://www.google.de/tagmanager/use-policy.html</a>
    </p>
    <h2>Widerruf</h2>
    <p>
      Sie können eine einmal erteilte Einwilligung zur Erhebung, Verarbeitung und Nutzung Ihrer
      personenbezogenen Daten jederzeit mit Wirkung für die Zukunft widerrufen. Ihre Daten
      werden dann durch {{ $prt.settings.realmName }} gelöscht. Bitte schicken Sie dazu eine E-Mail mit Nennung
      Ihres Namens und der ID der Wohnmarktanalyse an:
    </p>
    <p>
      {{ $prt.settings.realmName }} <br>
      {{ $prt.settings.dsgvo.address }} <br>
      {{ $prt.settings.dsgvo.plz }} <br>
      {{ $prt.settings.dsgvo.place }} <br>
      {{ $prt.settings.dsgvo.phone }} <br>
    </p>
    <h2>Einwilligung</h2>
    <p>
      Mit setzten des Häkchens durch anklicken von Gelesen und akzeptiert erkläre ich
      mich/erklären wir uns damit einverstanden, dass die {{ $prt.settings.realmName }} die oben
      beschriebenen Daten zu den genannten Zwecken verarbeitet.
      Ferner bin ich/sind wir mit einer Kontaktaufnahme durch einen Immobilienexperten
      der {{ $prt.settings.realmName }} einverstanden, der die Wohnmarktanalyse
      detailliert erläutert.
    </p>
    <p>
      Mir/uns ist bekannt, dass ich/wir meine/unsere Einwilligung jederzeit widerrufen
      kann/können.
    </p>
  </div>
</template>

<script>
export default {
  name: 'DsgvoPrivacyCookieContent'
}
</script>

<style>
.all-t-left * {
  text-align: left;
}
</style>
