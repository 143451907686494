<template>
  <div class="personal-data-slide slide-base"  data-step-name="Persönliche Daten">
    <div class="slide-content">
      <div class="slide-inner">
        <div class="main-section">
          <!-- Calculation Progress Section -->
          <div class="calculation-progress" v-if="!showForm">
            <div class="progress-bar-container">
              <div class="progress-bar-wrapper">
                <div class="progress-bar-fill" :style="{ width: progressPercentage + '%' }"></div>
              </div>
              <div class="progress-percentage">{{ progressPercentage }}%</div>
            </div>

            <div class="calculation-step">
              <div class="step-message">
                {{ calculationSteps[calculationStep]?.message || 'Starte Berechnung...' }}
              </div>
              <div class="step-animation">
                <LoadingBallPulse v-if="!emulatingFinished"/>
                <span v-else class="check-mark">✓</span>
              </div>
            </div>

            <!-- Data Visualization -->
            <div class="calculation-visuals" v-if="progressPercentage > 25">
              <div class="analysis-grid">
                <div class="analysis-item"
                     v-for="(item, index) in analysisItems"
                     :key="index"
                     :class="{ 'active': progressPercentage > item.activatesAt }">
                  <div class="analysis-icon">
                    <span class="icon-symbol">{{ item.icon }}</span>
                  </div>
                  <div class="analysis-content">
                    <div class="analysis-label">{{ item.label }}</div>
                    <div class="analysis-bar">
                      <div class="bar-fill" :style="{ width: getAnalysisProgress(item.activatesAt) + '%' }"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- Contact Form Section -->
          <transition name="fade"
                      @enter="handleTransitionEnter"
                      @after-enter="handleTransitionAfterEnter">
            <form v-if="showForm" ref="personalDataForm" class="personal-form">
              <!-- Salutation Selection -->
              <div class="form-section">
                <div class="segmented-control">
                  <label
                    v-for="option in ['Frau', 'Herr', 'Neutral']"
                    :key="option"
                    :class="{ active: form.salutation === option }"
                  >
                    <input
                      type="radio"
                      v-model="form.salutation"
                      :value="option"
                      name="salutation"
                    >
                    <span>{{ option }}</span>
                  </label>
                </div>
              </div>

              <!-- Name Fields -->
              <div class="form-section form-grid">
                <div class="form-group">
                  <input
                    v-model="form.firstName"
                    type="text"
                    placeholder="Vorname"
                    required
                    class="form-input"
                    @input="debounceCheckGender"
                  >
                </div>
                <div class="form-group">
                  <input
                    v-model="form.lastName"
                    type="text"
                    placeholder="Nachname"
                    required
                    class="form-input"
                  >
                </div>
              </div>

              <!-- Phone Number -->
              <div class="form-section">
                <div class="form-group">
                  <input
                    v-model="form.phoneNumber"
                    type="tel"
                    placeholder="Telefonnummer"
                    required
                    class="form-input"
                  >
                  <small class="input-hint">Für eventuelle Rückfragen</small>
                </div>
              </div>

              <!-- Privacy Notice -->
              <div class="form-section">
                <div class="privacy-notice">
                  <small>
                    Dieser Service ist kostenfrei. Mit dem Versenden stimmen Sie unseren
                    <a href="#" @click.prevent="$modal.show('privacy')">Datenschutzbestimmungen</a>
                    zu.
                  </small>
                </div>
              </div>

              <!-- Benefits Section -->
              <div class="benefits-section">
                <h3 class="benefits-title">Ihre Vorteile</h3>
                <div class="benefits-grid">
                  <div v-for="(benefit, index) in parsedBenefits"
                       :key="index"
                       class="benefit-item">
                    <span class="benefit-icon">✓</span>
                    <span class="benefit-text">{{ benefit }}</span>
                  </div>
                </div>
              </div>
            </form>
          </transition>
        </div>

        <div class="footer-section">
          <div v-if="showForm" class="navigation-buttons with-submit">
            <button type="submit" class="submit-button" @click="submit">
              <span>Bewertung anfordern</span>
              <i class="button-icon fas fa-arrow-right"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LoadingBallPulse from '@/components/icons/LoadingBallPulse'
import debounce from 'lodash/debounce'
import { cloudApiBaseUrl, cloudApiIframeKey } from '@/constants'
import slideMixin from '@/mixins/slideMixin'

export default {
  name: 'PersonalDataSlide',
  mixins: [slideMixin],
  components: {
    LoadingBallPulse
  },
  computed: {
    isTransitioning () {
      return this.$store.state.isTransitioning
    },
    parsedBenefits () {
      const tempDiv = document.createElement('div')
      tempDiv.innerHTML = this.$prt.settings.benefits
      const items = tempDiv.querySelectorAll('li')
      return Array.from(items).map(item => item.textContent.trim())
    },
    isActive () {
      return this.$store.state.currentSlide === 9 // Anpassen an den korrekten Index
    }
  },
  watch: {
    // Neuer watcher für isActive
    isActive: {
      immediate: true,
      handler (newValue) {
        if (newValue && !this.simulationStarted) {
          console.log('PersonalDataSlide is now active, starting rating...')
          this.$nextTick(() => {
            this.startRating()
          })
        }
      }
    }
  },
  data () {
    return {
      simulationStarted: false,
      simulationReady: false,
      emulatingFinished: false,
      calculationStep: null,
      progressPercentage: 0,
      showForm: false,
      resizeInterval: null,
      resizeObserver: null,
      mutationObserver: null,
      analysisItems: [
        {
          icon: '📍',
          label: 'Standortanalyse',
          activatesAt: 30
        },
        {
          icon: '📊',
          label: 'Marktdaten',
          activatesAt: 45
        },
        {
          icon: '🏠',
          label: 'Objektwerte',
          activatesAt: 60
        },
        {
          icon: '💰',
          label: 'Preisanalyse',
          activatesAt: 75
        }
      ],
      calculationSteps: [
        {
          message: 'Analysiere Standortdaten...',
          duration: 2000,
          progress: 20
        },
        {
          message: 'Werte Marktpreise der Umgebung aus...',
          duration: 2500,
          progress: 40
        },
        {
          message: 'Berechne Gebäudewerte...',
          duration: 2000,
          progress: 60
        },
        {
          message: 'Analysiere Wertefaktoren...',
          duration: 2000,
          progress: 80
        },
        {
          message: 'Finalisiere Immobilienbewertung...',
          duration: 1500,
          progress: 95
        }
      ],
      form: {
        salutation: 'Frau',
        firstName: '',
        lastName: '',
        phoneNumber: '',
        customSalutation: ''
      }
    }
  },
  created () {
    // Reset all states when component is created
    this.resetStates()
  },
  mounted () {
    console.log('PersonalDataSlide mounted')
    this.$nextTick(() => {
      this.resetStates()
      this.$emit('component-ready')

      // Start rating if slide is already active
      if (this.isActive && !this.simulationStarted) {
        console.log('PersonalDataSlide mounted and active, starting rating...')
        this.startRating()
      }
    })
  },
  methods: {
    resetStates () {
      console.log('Resetting PersonalDataSlide states')
      this.simulationStarted = false
      this.simulationReady = false
      this.emulatingFinished = false
      this.showForm = false
      this.calculationStep = null
      this.progressPercentage = 0
    },

    startRating () {
      console.log('startRating called', {
        simulationStarted: this.simulationStarted,
        simulationReady: this.simulationReady,
        showForm: this.showForm
      })

      // Reset states before starting
      this.resetStates()

      console.log('Starting rating simulation...')
      this.simulationStarted = true
      this.simulationReady = true
      this.startCalculation()
    },

    startCalculation () {
      console.log('Starting calculation...')
      if (!this.simulationReady) {
        return
      }

      this.progressPercentage = 0
      this.calculationStep = 0
      this.emulatingFinished = false
      this.showForm = false

      this.$nextTick(() => {
        this.processNextStep()
      })
    },

    processNextStep () {
      if (this.calculationStep === null || this.calculationStep === undefined) {
        this.calculationStep = 0
      }

      if (this.calculationStep >= this.calculationSteps.length) {
        this.finishCalculation()
        return
      }

      const step = this.calculationSteps[this.calculationStep]
      if (!step) {
        return
      }

      console.log('Processing step:', this.calculationStep, step.message)

      const startProgress = this.progressPercentage
      const endProgress = step.progress
      const duration = step.duration
      const startTime = Date.now()

      const animate = () => {
        const currentTime = Date.now()
        const elapsed = currentTime - startTime
        const progress = Math.min(elapsed / duration, 1)

        this.progressPercentage = Math.round(
          startProgress + (endProgress - startProgress) * progress
        )

        if (progress < 1) {
          requestAnimationFrame(animate)
        } else {
          if (this.calculationStep < this.calculationSteps.length) {
            this.calculationStep++
            setTimeout(() => {
              this.processNextStep()
            }, 500)
          }
        }
      }

      requestAnimationFrame(animate)
    },

    finishCalculation () {
      console.log('Finishing calculation...')
      this.emulatingFinished = true

      setTimeout(() => {
        this.showForm = true
        this.$nextTick(() => {
          if (window.parentIFrame) {
            if (typeof window.parentIFrame.size === 'function') {
              window.parentIFrame.size()
            } else if (typeof window.parentIFrame.resize === 'function') {
              window.parentIFrame.resize()
            }
          }
        })
      }, 1000)
    },

    getAnalysisProgress (activationThreshold) {
      if (this.progressPercentage < activationThreshold) return 0
      const progress = ((this.progressPercentage - activationThreshold) / (100 - activationThreshold)) * 100
      return Math.min(100, Math.max(0, progress))
    },

    handleTransitionEnter () {
      this.updateIframeSize()
    },

    handleTransitionAfterEnter () {
      this.updateIframeSize()
    },

    async submit () {
      if (!this.$refs.personalDataForm.checkValidity()) {
        this.$refs.personalDataForm.reportValidity()
        return
      }

      this.$store.commit('showLoader', true)
      const phoneNumber = this.convertToInternationalPhoneNumber(this.form.phoneNumber)

      if (!await this.validatePhoneNumberByTwilio(phoneNumber)) {
        this.$modal.show('dialog', {
          title: 'Telefonnummer ungültig',
          text: 'Bitte geben Sie eine gültige Telefonnummer ein!',
          buttons: [
            {
              title: 'Alles klar!'
            }
          ]
        })
        this.$store.commit('showLoader', false)
      } else {
        // Hier ist der kritische Teil
        if (this.$prt.settings.smsVerifyActive &&
          (this.$prt.settings.smsVerifyActive.toString().toLowerCase() === 'true')) {
          const smsRequestRes = await this.smsRequest(phoneNumber)
          this.$store.commit('showLoader', false)
          if (smsRequestRes) {
            this.$modal.show('sms')
          } else {
            this.$modal.show('smsWentWrong')
          }
        } else {
          // Wenn SMS-Verifikation nicht aktiv ist, direkt zur Bewertung
          await this.rateProperty()
        }
      }
    },

    async validatePhoneNumberByTwilio (phoneNumber) {
      const body = new URLSearchParams()
      body.append('key', cloudApiIframeKey)
      body.append('phoneNumber', phoneNumber)
      const options = {
        credentials: 'include',
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'Referrer-Policy': 'strict-origin-when-cross-origin'
        },
        body: body
      }
      try {
        const resp = await fetch(cloudApiBaseUrl + '/iframe/validatePhoneNumberByTwilio', options)
        const respText = await resp.text()
        if (respText === 'VALID') return true
      } catch (e) {
        console.error(e)
      }
      return false
    },

    async smsRequest (phoneNumber) {
      const body = new URLSearchParams()
      body.append('key', cloudApiIframeKey)
      body.append('phoneNumber', phoneNumber)
      const options = {
        credentials: 'include',
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        body: body
      }

      try {
        const resp = await fetch(cloudApiBaseUrl + '/iframe/smsRequest', options)
        const respText = await resp.text()
        if (respText !== 'NOT_VALID') {
          this.$store.commit('setTwilioSId', respText)
          return true
        }
      } catch (e) {
        console.error(e)
      }
      return false
    },

    convertToInternationalPhoneNumber (number) {
      const cleaned = number.replace(/[^0-9+]/g, '')
      if (cleaned.startsWith('+')) {
        return cleaned
      } else if (cleaned.startsWith('0')) {
        return '+49' + cleaned.substring(1)
      }
      return ''
    },

    debounceCheckGender: debounce(function () {
      this.checkGender()
    }, 300),

    async checkGender () {
      if (this.form.firstName.length < 2) return

      try {
        const response = await fetch(`${cloudApiBaseUrl}/backend/gender?name=${this.form.firstName}`)
        const data = await response.json()

        if (data.accuracy >= 90) {
          this.form.salutation = data.gender === 'male' ? 'Herr' : 'Frau'
        } else if (data.accuracy >= 70) {
          this.form.salutation = data.gender === 'male' ? 'Herr' : 'Frau'
        } else {
          this.form.salutation = 'Neutral'
        }
      } catch (error) {
        console.error('Error fetching gender data:', error)
      }
    },

    async rateProperty () {
      await this.$store.dispatch('rateProperty', this.form)
    }
  },
  beforeDestroy () {
    this.resetStates()
  }
}
</script>

<style lang="scss">
@import '@/assets/css/_layout.scss';

.personal-data-slide {
  .header-section {
    padding-top: 3rem;
    text-align: center;
    margin-bottom: 2rem;

    &.success {
      .success-icon {
        font-size: 3rem;
        color: #20bf6b;
        margin-bottom: 1rem;
        animation: scaleIn 0.5s ease-out;
      }
    }

    .title {
      font-size: 2rem;
      color: var(--primary-color);
      margin-bottom: 0.5rem;
      line-height: 1.3;
      font-weight: 600;
    }
  }

  .calculation-step {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem;
    padding: 1rem;
    background: #f8f9fa;
    border-radius: 12px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);

    .step-message {
      font-size: 1.1rem;
      color: var(--primary-color);
      font-weight: 500;
      flex-grow: 1;
      margin-right: 1rem;
    }

    .step-animation {
      flex-shrink: 0;
    }
  }

  // Calculation Progress Styles
  .calculation-progress {
    .progress-bar-container {
      margin-bottom: 2rem;

      .progress-bar-wrapper {
        background: #f0f0f0;
        height: 8px;
        border-radius: 4px;
        overflow: hidden;
        box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);

        .progress-bar-fill {
          height: 100%;
          background: linear-gradient(90deg,
            var(--secondary-color) 0%,
            var(--primary-color) 100%);
          transition: width 0.3s ease;
          box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
        }
      }
    }
  }

  .calculation-visuals {
    margin: 2rem 0;
    padding: 1.5rem;
    background: #f8f9fa;
    border-radius: 16px;
    box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.05);

    .analysis-grid {
      display: grid;
      gap: 1.25rem;

      .analysis-item {
        display: flex;
        align-items: center;
        gap: 1rem;
        opacity: 0.4;
        transform: translateX(-10px);
        transition: all 0.5s ease;

        &.active {
          opacity: 1;
          transform: translateX(0);
        }

        .analysis-icon {
          width: 40px;
          height: 40px;
          display: flex;
          align-items: center;
          justify-content: center;
          background: white;
          border-radius: 12px;
          box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);

          .icon-symbol {
            font-size: 1.25rem;
          }
        }

        .analysis-content {
          flex: 1;

          .analysis-label {
            font-size: 0.9rem;
            font-weight: 500;
            color: var(--primary-color);
            margin-bottom: 0.5rem;
          }

          .analysis-bar {
            height: 6px;
            background: rgba(0, 0, 0, 0.05);
            border-radius: 3px;
            overflow: hidden;

            .bar-fill {
              height: 100%;
              background: linear-gradient(90deg,
                var(--secondary-color) 0%,
                var(--primary-color) 100%);
              transition: width 0.8s ease;
              border-radius: 3px;
              box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
            }
          }
        }
      }
    }
  }

  // Form Styles
  .personal-form {
    .form-section {
      margin-bottom: 1.5rem;
    }

    .form-grid {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 1rem;
    }

    .segmented-control {
      display: flex;
      background: #f5f5f5;
      border-radius: 12px;
      padding: 0.25rem;
      margin-bottom: 1rem;

      label {
        flex: 1;
        position: relative;
        cursor: pointer;
        padding: 0.75rem;
        text-align: center;
        color: #666;
        transition: all 0.3s ease;
        min-height: 44px;

        input {
          position: absolute;
          opacity: 0;
        }

        &.active {
          background: white;
          border-radius: 10px;
          color: var(--primary-color);
          box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
        }
      }
    }

    .form-input {
      width: 100%;
      padding: 1rem;
      border: 2px solid #e0e0e0;
      border-radius: 12px;
      font-size: 1rem;
      transition: all 0.3s ease;
      min-height: 44px;

      &:focus {
        border-color: var(--secondary-color);
        box-shadow: 0 0 0 3px rgba(23, 181, 160, 0.1);
        outline: none;
      }
    }

    .input-hint {
      display: block;
      color: #666;
      margin-top: 0.5rem;
      font-size: 0.875rem;
    }
  }

  .privacy-notice {
    text-align: center;
    margin-top: 1rem;

    small {
      color: #666;
      font-size: 0.875rem;
    }

    a {
      color: var(--secondary-color);
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .benefits-section {
    margin-top: 2.5rem;
    padding-top: 2rem;
    border-top: 1px solid #eee;
    margin-bottom: 1rem;

    .benefits-title {
      font-size: 1.25rem;
      color: var(--primary-color);
      margin-bottom: 1.5rem;
      font-weight: 500;
      text-align: left;
    }

    .benefits-grid {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
      gap: 1.25rem;
      padding-bottom: 1rem;

      .benefit-item {
        display: flex;
        align-items: flex-start;
        gap: 0.75rem;
        text-align: left;
        padding: 1rem;
        background: #f8f9fa;
        border-radius: 12px;
        transition: all 0.2s ease;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
        min-height: 44px;

        &:hover {
          transform: translateY(-2px);
          box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
          background: #fff;
        }

        .benefit-icon {
          color: var(--secondary-color);
          font-size: 1.25rem;
          font-weight: bold;
          flex-shrink: 0;
          margin-top: 2px;
        }

        .benefit-text {
          color: #4a5568;
          font-size: 0.95rem;
          line-height: 1.5;
        }
      }
    }
  }

  .footer-section {
    .navigation-buttons.with-submit {
      .nav-button {
        // Standard Button-Styles von slide-base
      }

      .submit-button {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        gap: 0.75rem;
        padding: 0 1.5rem;
        height: 44px;
        border: none;
        border-radius: 12px;
        background: var(--secondary-color);
        color: white;
        font-size: 1rem;
        font-weight: 600;
        cursor: pointer;
        transition: all 0.3s ease;
        min-width: 200px; // Breiter für mehr Prominenz

        .button-icon {
          font-size: 1.25rem;
          transition: transform 0.3s ease;
        }

        &:hover {
          background: var(--secondary-color-dark);
          transform: translateY(-2px);

          .button-icon {
            transform: translateX(4px);
          }
        }
      }
    }

    @media (max-width: 768px) {
      .main-section {
        padding: 1rem;
      }

      .form-grid {
        grid-template-columns: 1fr;
      }

      .benefits-section {
        .benefits-grid {
          grid-template-columns: 1fr;
          gap: 1rem;
        }
      }

      .footer-section {
        padding: 1rem;

        .navigation-buttons {
          flex-direction: column-reverse;

          .nav-button {
            width: 100%;
            justify-content: center;
          }

          .detail-value {
            font-size: 1rem;
          }
        }
      }
    }
  }
}
</style>
