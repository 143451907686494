<template>
  <div class="construction-possibilities-slide slide-base"  data-step-name="Bebauungsmöglichkeiten">
    <div class="slide-content">
      <div class="slide-inner">
        <div class="main-section">
          <!-- Header Section -->
          <div class="header-section">
            <h1 class="slide-title" v-html="title"></h1>
            <p class="slide-description">Welche Bebauungsmöglichkeiten bestehen für das Grundstück?</p>
          </div>

          <!-- Options Grid -->
          <div class="options-grid">
            <button
              class="option-card"
              @click="next('Kurzfristig')"
            >
              <div class="icon-container">
                <i class="fas fa-border-all"></i>
              </div>
              <div class="option-content">
                <span class="option-title">Kurzfristig</span>
                <span class="option-description">Sofortige Bebauung möglich</span>
              </div>
              <span class="hover-arrow">→</span>
            </button>

            <button
              class="option-card"
              @click="next('Eingeschränkt')"
            >
              <div class="icon-container">
                <i class="far fa-exclamation-circle"></i>
              </div>
              <div class="option-content">
                <span class="option-title">Eingeschränkt</span>
                <span class="option-description">Bebauung mit Auflagen möglich</span>
              </div>
              <span class="hover-arrow">→</span>
            </button>

            <button
              class="option-card"
              @click="next('Nicht bebaubar')"
            >
              <div class="icon-container">
                <i class="fas fa-ban"></i>
              </div>
              <div class="option-content">
                <span class="option-title">Nicht bebaubar</span>
                <span class="option-description">Keine Bebauung möglich</span>
              </div>
              <span class="hover-arrow">→</span>
            </button>

            <button
              class="option-card"
              @click="next('Weiß nicht')"
            >
              <div class="icon-container">
                <i class="far fa-question-circle"></i>
              </div>
              <div class="option-content">
                <span class="option-title">Weiß nicht</span>
                <span class="option-description">Keine Informationen vorhanden</span>
              </div>
              <span class="hover-arrow">→</span>
            </button>
          </div>
        </div>

        <div class="footer-section">
          <div class="navigation-buttons">
            <button class="nav-button prev-button" @click="prev">
              <i class="button-icon fas fa-arrow-left"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import slideMixin from '@/mixins/slideMixin'

export default {
  name: 'ConstructionPossibilitiesSlide',
  mixins: [slideMixin],
  computed: {
    isTransitioning () {
      return this.$store.state.isTransitioning
    }
  },
  props: {
    title: String
  },
  methods: {
    next (choice) {
      this.$store.commit('updateCollectedData', { constructionPossibilities: choice })
      this.$store.commit('nextSlide')
    },
    prev () {
      this.$store.commit('prevSlide')
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/css/_layout.scss';

.site-layout-slide,
.developed-property-slide,
.construction-possibilities-slide,
.site-area-slide {
  .header-section {
    text-align: center;
    margin-bottom: 2rem;

    .slide-title {
      font-size: clamp(1.5rem, 4vw, 2rem);
      color: var(--primary-color);
      font-weight: 600;
      margin-bottom: 0.5rem;
      line-height: 1.3;
    }

    .slide-description {
      font-size: 1.1rem;
      color: #666;
      margin: 0;
    }
  }

  .options-grid {
    display: grid;
    gap: 1rem;
  }

  .option-card {
    position: relative;
    display: grid;
    grid-template-columns: 80px 1fr auto;
    align-items: center;
    gap: 1.25rem;
    padding: 1.5rem;
    background: #f8fafc;
    border: 2px solid transparent;
    border-radius: 12px;
    cursor: pointer;
    transition: all 0.3s ease;
    text-align: left;
    width: 100%;
    min-height: 44px;

    &:hover {
      transform: translateY(-2px);
      border-color: var(--secondary-color);
      background: white;
      box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);

      .hover-arrow {
        opacity: 1;
        transform: translateX(0);
      }

      .icon-container {
        background: rgba(23, 181, 160, 0.1);

        i, svg {
          color: var(--secondary-color);
        }
      }
    }

    .icon-container {
      width: 64px;
      height: 64px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #f1f5f9;
      border-radius: 16px;
      transition: all 0.3s ease;

      i, svg {
        font-size: 28px;
        color: #64748b;
        transition: all 0.3s ease;
      }
    }

    .option-content {
      display: flex;
      flex-direction: column;
      gap: 0.25rem;
      flex: 1;
      min-width: 0;
    }

    .option-title {
      font-size: 1.1rem;
      color: var(--primary-color);
      font-weight: 500;
    }

    .option-description {
      font-size: 0.9rem;
      color: #666;
      line-height: 1.4;
    }

    .hover-arrow {
      font-size: 1.25rem;
      color: var(--secondary-color);
      opacity: 0;
      transform: translateX(-10px);
      transition: all 0.3s ease;
      padding-left: 0.5rem;
    }
  }

  @media (max-width: 768px) {
    .main-section {
      padding: 1rem;

      .header-section {
        margin-bottom: 1.5rem;

        .slide-title {
          font-size: 1.5rem;
        }

        .slide-description {
          font-size: 1rem;
        }
      }

      .option-card {
        grid-template-columns: 60px 1fr auto;
        padding: 1.25rem;
        gap: 1rem;

        .icon-container {
          width: 48px;
          height: 48px;

          i, svg {
            font-size: 24px;
          }
        }

        .option-title {
          font-size: 1rem;
        }

        .option-description {
          font-size: 0.85rem;
        }

        .hover-arrow {
          display: none;
        }
      }
    }

    .footer-section {
      padding: 1rem;

      .navigation-buttons {
        flex-direction: column;

        .nav-button {
          width: 100%;
          justify-content: center;
        }
      }
    }
  }
}
</style>
