<template>
  <div class="home-slide slide-base" >
    <div class="slide-content">
      <div class="slide-inner">
        <!-- Main Section - alles scrollbar -->
        <div class="main-section">
          <!-- Header ist jetzt Teil des scrollbaren Bereichs -->
          <div class="header-section">
            <h1 class="main-title" v-html="this.$prt.settings.text.start"></h1>
            <div class="slogan-container">
              <h2 class="slogan" v-html="this.$prt.settings.text.startSlogan1"></h2>
              <h1 class="slogan-highlight" v-html="this.$prt.settings.text.startSlogan2"></h1>
            </div>
          </div>

          <!-- Property Type Selection -->
          <div class="property-type-grid">
            <div
              v-for="type in filteredPropertyTypes"
              :key="type.id"
              class="property-card"
              :class="{ active: collectedData.chosenType === type.id }"
              @click="choose(type.id)"
            >
              <div class="icon-container">
                <component :is="type.icon"/>
              </div>
              <span class="type-label">{{ type.label }}</span>
            </div>
          </div>

          <!-- DSGVO Section -->
          <div
            v-if="$commons.getSafe(() => $prt.settings.dsgvo.active)"
            class="dsgvo-section"
            :class="{ active: collectedData.chosenType }"
          >
            <div class="checkbox-container">
              <input
                v-model="allowDsgvo"
                ref="allowDsgvo"
                type="checkbox"
                id="dsgvo-allow"
                name="dsgvo-allow"
                value="1"
                required
              />
              <label for="dsgvo-allow">
                Ja, ich habe die <a @click.prevent="$modal.show('dsgvoCookie')" href="#" class="dsgvo-link">
                <strong>Erklärung zum Datenschutz</strong>
              </a> gelesen und stimme zu.
              </label>
            </div>

            <button
              @click="handleRateNowClick"
              :disabled="!allowDsgvo"
              class="rate-now-button"
            >
              Jetzt kostenlos bewerten
              <span class="button-icon">→</span>
            </button>
          </div>
        </div>

        <!-- Footer Section - Bleibt immer unten -->
        <div class="footer-section">
          <div class="powered-by">
            powered by <a href="https://www.leadmeup.de" target="_blank" rel="noopener">LeadMeUp</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import WohnungIcon from '@/assets/icons/wohnung.svg'
import HausIcon from '@/assets/icons/haus.svg'
import GrundstuckIcon from '@/assets/icons/grundstuck.svg'
import MieteIcon from '@/assets/icons/miete.svg'
import BusinessIcon from '@/assets/icons/industrie-oder-geschaeftsgebaeude.svg'
import slideMixin from '@/mixins/slideMixin'

export default {
  name: 'HomeSlide',
  mixins: [slideMixin],
  components: {
    WohnungIcon,
    HausIcon,
    GrundstuckIcon,
    MieteIcon,
    BusinessIcon
  },
  computed: {
    ...mapState(['collectedData']),
    propertyTypes () {
      return [
        { id: 'apartment', label: 'Wohnung', icon: WohnungIcon },
        { id: 'house', label: 'Haus', icon: HausIcon },
        { id: 'land', label: 'Grundstück', icon: GrundstuckIcon },
        { id: 'rent', label: 'Miete', icon: MieteIcon },
        { id: 'business', label: 'Gewerbe', icon: BusinessIcon }
      ]
    },
    filteredPropertyTypes () {
      return this.propertyTypes.filter(type =>
        this.$prt.settings.activeSteps.includes(type.id)
      )
    }
  },
  data () {
    return {
      allowDsgvo: 0
    }
  },
  methods: {
    choose (type) {
      this.$store.commit('updateCollectedData', { chosenType: type })
      // Wenn DSGVO nicht aktiv ist, direkt zum nächsten Slide
      if (!this.$commons.getSafe(() => this.$prt.settings.dsgvo.active)) {
        this.$store.commit('updateCollectedData', { type })
        setTimeout(() => {
          this.$store.commit('nextSlide')
        }, 60)
      } else {
        // Wenn DSGVO aktiv ist, nur den Typ setzen (kein Slide-Wechsel)
        this.$store.commit('updateCollectedData', { type })
      }
    },
    handleRateNowClick () {
      if (!this.allowDsgvo) {
        this.$refs.allowDsgvo.reportValidity()
      } else {
        // DSGVO wurde akzeptiert, jetzt können wir zum nächsten Slide
        setTimeout(() => {
          this.$store.commit('nextSlide')
        }, 60)
      }
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/css/_layout.scss';

.home-slide {
  .header-section {
    text-align: center;

    .main-title {
      color: var(--primary-color);
      font-weight: 600;
    }

    .slogan-container {
      max-width: 800px;
      margin: 0 auto;
    }

    .slogan {
      color: #4a5568;
      font-weight: 400;
      margin-bottom: 0.5rem;
    }

    .slogan-highlight {
      color: var(--secondary-color);
      font-weight: 600;
    }
  }

  .property-type-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
    gap: 1.5rem;
    margin: 1.5rem 0;

    @media (max-width: 768px) {
      grid-template-columns: repeat(2, 1fr);
      gap: 1rem;
    }
  }

  .property-card {
    background: #f8fafc;
    border: 2px solid transparent;
    border-radius: 16px;
    padding: 1.5rem;
    cursor: pointer;
    transition: all 0.3s ease;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    height: auto;
    min-height: 44px;

    &:hover {
      transform: translateY(-4px);
      box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
    }

    &.active {
      background: rgba(23, 181, 160, 0.1);
      border-color: var(--secondary-color);
      box-shadow: 0 4px 12px rgba(23, 181, 160, 0.2);
    }

    .icon-container {
      width: 48px;
      height: 48px;
      display: flex;
      align-items: center;
      justify-content: center;

      @media (max-width: 768px) {
        width: 32px;
        height: 32px;
      }

      // Neue Styles für SVG
      svg {
        width: 100%;
        height: 100%;
        display: block; // Verhindert unerwünschte Whitespace

        // Standardfarbe für den prt-icon-color Path
        .prt-icon-color {
          fill: var(--secondary-color, #17B5A0);
        }
      }
    }

    .property-card:hover {
      .icon-container svg .prt-icon-color {
        fill: var(--secondary-color-dark, #149183);
      }
    }

    .type-label {
      font-size: 1.1rem;
      font-weight: 500;
      color: var(--primary-color);

      @media (max-width: 768px) {
        font-size: 0.9rem;
      }
    }

    @media (max-width: 768px) {
      padding: 1rem;
    }
  }

  .dsgvo-section {
    background: #f8fafc;
    border-radius: 16px;
    padding: 1.5rem;
    margin: 1.5rem 0;
    opacity: 0.6;
    transform: translateY(10px);
    transition: all 0.3s ease;

    &.active {
      opacity: 1;
      transform: translateY(0);
    }

    .checkbox-container {
      display: flex;
      align-items: flex-start;
      gap: 0.75rem;
      margin-bottom: 1.5rem;

      input[type="checkbox"] {
        width: 20px;
        height: 20px;
        margin-top: 0.25rem;
        accent-color: var(--secondary-color);
      }

      label {
        font-size: 0.95rem;
        color: #4a5568;
        line-height: 1.5;
        text-align: left;
      }

      .dsgvo-link {
        color: var(--secondary-color);
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }
    }

    @media (max-width: 768px) {
      padding: 1rem;
      margin: 1rem 0;

      .checkbox-container {
        gap: 0.5rem;

        label {
          font-size: 0.9rem;
        }
      }
    }
  }

  .rate-now-button {
    width: 100%;
    padding: 1rem;
    background: var(--secondary-color);
    color: white;
    border: none;
    border-radius: 12px;
    font-size: 1.1rem;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    min-height: 44px;

    &:disabled {
      background: #cbd5e0;
      cursor: not-allowed;
      transform: none !important;
    }

    &:not(:disabled):hover {
      background: var(--secondary-color-dark);
      transform: translateY(-2px);
    }

    .button-icon {
      font-size: 1.25rem;
      transition: transform 0.3s ease;
    }

    &:hover .button-icon {
      transform: translateX(4px);
    }

    @media (max-width: 768px) {
      font-size: 1rem;
      padding: 0.875rem;
    }
  }

  .footer-section {
    .powered-by {
      text-align: center;
      font-size: 0.8rem;
      color: #666;

      a {
        color: inherit;
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}
</style>
