import _ from 'lodash'

// noinspection DuplicatedCode
const defaultSettings = {
  reasonsWhenBuying: ['Verkauf', 'Kauf'],
  reasonsWhenRenting: ['Vermieten', 'Mieten'],
  text: {
    start: 'Welche Immobilie möchten Sie <b>verkaufen?</b>',
    startSlogan1: '100% <span class="prt-color-secondary">kostenlos</span> bewerten lassen',
    startSlogan2: 'Welche Immobilie möchten Sie bewerten?',
    startButton: 'Jetzt Bewertung anfordern!',
    region: 'Bitte geben Sie die <b>Adresse</b> Ihrer Immobilie ein.',
    personalDataForm: 'Fordern Sie jetzt Ihr Angebot von leadmeup.de an.',
    thanks: 'Wir melden uns bei Ihnen, so schnell wie möglich!',
    deactivatedRate1: 'Danke für Ihr Vertrauen!',
    deactivatedRate2: 'Wir melden uns so bald wie möglich!',
    notFound1: 'Leider konnten wir anhand Ihrer Daten keine Bewertung generieren.!',
    notFound2: 'Wir melden uns so bald wie möglich',
    extraMessage: '',
    reason: 'Wozu benötigen Sie die Immobilienbewertung?',
    reasonWhen: 'Wann möchten Sie voraussichtlich verkaufen?',
    propertyIsRating: 'Der Wert Ihrer Immobilie wird ermittelt...',
    propertyRated: 'Der Wert Ihrer Immobilie wurde ermittelt!',
    propertyValueIs: 'Ihr Immobilienwert beträgt {{ WERT }}',
    emailTitle: 'An wen dürfen wir die <b>Bewertung</b> versenden?',
    emailBody: 'Bitte achten Sie auf die korrekte Schreibweise Ihrer E-Mail-Adresse.<br><br><b>Wichtig:</b> Überprüfen Sie auch Ihren Spam-Ordner.',

    apartment: {
      livingArea: 'Wie ist die <b>Wohnfläche</b> der Wohnung?',
      floor: 'In welcher <b>Etage</b> befindet sich die Wohnung?',
      roomCount: 'Wie viele <b>Zimmer</b> hat die Wohnung?',
      constructionYear: 'Wann wurde das Wohnhaus <b>gebaut?</b>'
    },

    house: {
      siteArea: 'Welche Fläche hat das <b>Grundstück</b> des Hauses?',
      houseType: 'Bitte wählen Sie die Art des Hauses aus.',
      livingArea: 'Wie ist die gesamte <b>Wohnfläche</b> des Hauses?',
      floor: 'Wie viele <b>Etagen</b> hat das Haus?',
      roomCount: 'Wie viele <b>Zimmer</b> hat das Haus?',
      constructionYear: 'Wann wurde das <b>Haus</b> gebaut?'
    },

    land: {
      siteArea: 'Welche Fläche hat das <b>Grundstück</b>?',
      developedProperty: 'Ist das Grundstück <b>erschlossen</b>?',
      constructionPossibilities: 'Wie sind die <b>Bebauungsmöglichkeiten</b>?',
      siteLayout: 'Wie ist der <b>Grundstückszuschnitt</b>?'
    },

    rent: {
      livingArea: 'Wie ist die <b>Wohnfläche</b> der Wohnung?',
      rentType: 'Um was <b>handelt</b> es sich hierbei?',
      rentCategory: 'Welcher <b>Typ</b> passt zur Ihrer Immobilie?',
      constructionYear: 'Wann wurde das Wohnhaus <b>gebaut?</b>'
    },
    business: {
      businessType: 'Um welche Gebäudeart handelt es sich?',
      livingArea: 'Wie groß ist die Gewerbefläche der Immobilie?',
      siteArea: 'Welche Fläche hat das Grundstück des Gewerbes?',
      isRent: 'Ist die Immobilie vermietet?',
      constructionYear: 'Wann wurde das Haus erbaut?'
    }
  }
}

const SettingsPlugin = {
  getSetting (key, def) {
    return _.has(window.prt.settings, key) ? _.get(window.prt.settings, key) : def
  },
  install (Vue, options) {
    _.defaultsDeep(window.prt.settings, defaultSettings)
    if (typeof window.prt.settings.activeSteps === 'string') window.prt.settings.activeSteps = JSON.parse(window.prt.settings.activeSteps)
    if (typeof window.prt.settings.trackingVia === 'string') window.prt.settings.trackingVia = JSON.parse(window.prt.settings.trackingVia)
    if (typeof window.prt.settings.reasonsWhenBuying === 'string') window.prt.settings.reasonsWhenBuying = JSON.parse(window.prt.settings.reasonsWhenBuying)
    if (typeof window.prt.settings.reasonsWhenRenting === 'string') window.prt.settings.reasonsWhenRenting = JSON.parse(window.prt.settings.reasonsWhenRenting)
    if (typeof window.prt.settings.extraSteps === 'string') window.prt.settings.extraSteps = JSON.parse(window.prt.settings.extraSteps)

    Vue.prototype.$prt = { ...window.prt, debug: options.debug || false, getSetting: this.getSetting }
  }
}

export default SettingsPlugin
