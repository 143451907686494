export default {
  methods: {
    updateIframeSize () {
      if (window.parentIFrame && typeof window.parentIFrame.resize === 'function') {
        window.parentIFrame.resize()
      }
    }
  },
  mounted () {
    this.updateIframeSize()
  },
  updated () {
    this.updateIframeSize()
  }
}
