function removeTrailingSlash (val) {
  if (typeof val === 'undefined') return
  return val.replace(/\/$/, '')
}

export const cloudApiBaseUrl = removeTrailingSlash(process.env.VUE_APP_CLOUD_API_BASE_URL) || 'https://localhost:8080'
// noinspection JSUnresolvedVariable
export const cloudApiIframeKey = window.prt.settings.iframeKey || new URLSearchParams(window.location.search).get('key')
// noinspection JSUnresolvedVariable
export const cloudApiReferer = window.prt.referer
// noinspection JSUnresolvedVariable
export const cloudApiDontTrack = window.prt.settings.dontTrackPreview // preview

export const realmSettings = window.prt.settings || {}
