<template>
  <div class="results-deactivated-slide slide-base"  data-step-name="Deaktivierte Bewertung">
    <div class="slide-content">
      <div class="slide-inner">
        <div class="main-section">
          <!-- Message Container -->
          <div class="message-container">
            <!-- Success Icon -->
            <div class="success-icon">
              <i class="far fa-check-circle"></i>
            </div>

            <!-- Main Message -->
            <h1 class="title" v-html="this.$prt.settings.text.deactivatedRate1"></h1>

            <!-- Subtitle -->
            <h2 class="subtitle" v-html="this.$prt.settings.text.deactivatedRate2"></h2>
          </div>
        </div>

        <div class="footer-section"></div>
      </div>
    </div>
  </div>
</template>

<script>
import slideMixin from '@/mixins/slideMixin'

export default {
  name: 'ResultsDeactivatedSlide',
  mixins: [slideMixin]
}
</script>

<style lang="scss">
@import '@/assets/css/_layout.scss';

.results-deactivated-slide {
  .message-container {
    text-align: center;
    padding: 2rem;
    background: #f8fafc;
    border-radius: 12px;
    animation: fadeIn 0.5s ease-out;

    .success-icon {
      font-size: 3rem;
      color: #20bf6b;
      margin-bottom: 1.5rem;
      animation: scaleIn 0.5s ease-out;
    }

    .title {
      font-size: 2rem;
      color: var(--primary-color);
      font-weight: 600;
      margin-bottom: 1rem;
      line-height: 1.3;
    }

    .subtitle {
      font-size: 1.25rem;
      color: #666;
      font-weight: 400;
    }
  }

  @keyframes scaleIn {
    from {
      transform: scale(0);
      opacity: 0;
    }
    to {
      transform: scale(1);
      opacity: 1;
    }
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  @media (max-width: 768px) {
    .main-section {
      padding: 1rem;
    }

    .message-container {
      padding: 1.5rem;

      .success-icon {
        font-size: 2.5rem;
        margin-bottom: 1rem;
      }

      .title {
        font-size: 1.5rem;
      }

      .subtitle {
        font-size: 1.1rem;
      }
    }
  }
}
</style>
