<template>
  <div class="chat-container">
    <div class="chat-window">
      <div class="chat-header">
        <h2>{{ chatbotName }}</h2>
        <div class="header-actions">
          <button @click="toggleTheme" class="theme-toggle" :aria-label="isDarkMode ? 'Switch to light mode' : 'Switch to dark mode'">
            <svg v-if="isDarkMode" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 3v1m0 16v1m9-9h-1M4 12H3m15.364 6.364l-.707-.707M6.343 6.343l-.707-.707m12.728 0l-.707.707M6.343 17.657l-.707.707M16 12a4 4 0 11-8 0 4 4 0 018 0z" />
            </svg>
            <svg v-else xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M20.354 15.354A9 9 0 018.646 3.646 9.003 9.003 0 0012 21a9.003 9.003 0 008.354-5.646z" />
            </svg>
          </button>
          <button @click="clearChat" class="clear-chat" aria-label="Clear chat">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
            </svg>
          </button>
        </div>
      </div>
      <div class="messages" ref="messageContainer">
        <div v-for="message in messages" :key="message.id" :class="['message', message.sender === 'User' ? 'User' : 'Bot']">
          <div class="message-content" v-html="formatMessage(message.content)"></div>
          <div class="message-time">{{ formatTime(message.createdAt) }}</div>
        </div>
        <div v-if="isLoading" class="message Bot">
          <div class="message-content loading">
            <span class="dot"></span>
            <span class="dot"></span>
            <span class="dot"></span>
          </div>
        </div>
      </div>
      <div class="input-area">
        <input v-model="userMessage" @keyup.enter="sendMessage" placeholder="Geben Sie Ihre Nachricht ein..." />
        <button @click="sendMessage" aria-label="Send message">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="send-icon">
            <path d="M3.478 2.405a.75.75 0 00-.926.94l2.432 7.905H13.5a.75.75 0 010 1.5H4.984l-2.432 7.905a.75.75 0 00.926.94 60.519 60.519 0 0018.445-8.986.75.75 0 000-1.218A60.517 60.517 0 003.478 2.405z" />
          </svg>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { marked } from 'marked'
import Stomp from 'stompjs'
import SockJS from 'sockjs-client'

export default {
  name: 'ChatWindow',
  data () {
    return {
      chatbotName: 'Chatbot',
      messages: [],
      userMessage: '',
      isLoading: false,
      stompClient: null,
      isDarkMode: false,
      userId: null
    }
  },
  mounted () {
    this.userId = this.generateUserId()
    this.setBotIdFromUrl()
    this.connectToWebSocket()
    this.loadChatbotData()
    this.loadChatHistoryFromLocalStorage()
  },
  methods: {
    generateUserId () {
      return 'user-' + Math.random().toString(36).substr(2, 9)
    },
    setBotIdFromUrl () {
      const params = new URLSearchParams(window.location.search)
      const botId = params.get('botId')
      if (botId) {
        this.botId = botId
      }
    },
    connectToWebSocket () {
      const socket = new SockJS(process.env.VUE_APP_WS_URL + '/ws')
      this.stompClient = Stomp.over(socket)

      this.stompClient.connect({}, (frame) => {
        console.log('Connected: ' + frame)

        this.stompClient.subscribe('/topic/public', (message) => {
          const receivedMessage = JSON.parse(message.body)

          if (Array.isArray(receivedMessage)) {
            receivedMessage.forEach((msg) => {
              this.processReceivedMessage(msg)
            })
          } else {
            this.processReceivedMessage(receivedMessage)
          }
        })

        this.fetchChatHistory()
      }, (error) => {
        console.error('Error connecting to WebSocket:', error)
      })
    },
    processReceivedMessage (message) {
      if (message && message.content && message.sender) {
        this.messages.push({
          id: message.id || `local-${Date.now()}`,
          sender: message.sender,
          content: message.content,
          createdAt: message.createdAt || new Date().toISOString(),
          messageType: message.messageType || 'CHAT'
        })
        this.saveChatToLocalStorage()
        this.$nextTick(() => {
          this.scrollToBottom()
        })
      }
    },
    sendMessage () {
      if (this.userMessage.trim() === '') return

      const chatMessage = {
        sender: 'User',
        content: this.userMessage,
        messageType: 'CHAT',
        chatbotId: Number(this.botId),
        createdAt: new Date().toISOString(),
        userId: this.userId
      }

      // Sofortige Anzeige der User-Nachricht
      this.messages.push(chatMessage)
      this.saveChatToLocalStorage()

      // Sende die Nachricht an den Server
      this.stompClient.send('/app/chat.sendMessage', {}, JSON.stringify(chatMessage))

      // Leere das Eingabefeld
      this.userMessage = ''

      this.$nextTick(() => {
        this.scrollToBottom()
      })

      this.showTypingAnimation()
    },
    fetchChatHistory () {
      const requestPayload = {
        chatbotId: this.botId,
        userId: this.userId
      }
      this.stompClient.send('/app/chat.getHistory', {}, JSON.stringify(requestPayload))
    },
    loadChatbotData () {
      const pluginBaseUrl = process.env.VUE_APP_PLUGIN_BASE_URL
      const apiUrl = `${pluginBaseUrl}/webscraping/chatbots/${this.botId}`

      fetch(apiUrl)
        .then((response) => response.json())
        .then((data) => {
          if (data && data.name) {
            this.chatbotName = data.name
          } else {
            console.error('Chatbot data not available.')
          }
        })
        .catch((error) => {
          console.error('Error loading chatbot data:', error)
        })
    },
    toggleTheme () {
      this.isDarkMode = !this.isDarkMode
    },
    saveChatToLocalStorage () {
      localStorage.setItem('chatMessages', JSON.stringify(this.messages))
    },
    loadChatHistoryFromLocalStorage () {
      const storedMessages = JSON.parse(localStorage.getItem('chatMessages'))
      if (storedMessages && storedMessages.length > 0) {
        this.messages = storedMessages
        this.$nextTick(() => {
          this.scrollToBottom()
        })
      }
    },
    clearChat () {
      if (window.confirm('Bist du sicher, dass du den Chatverlauf löschen möchtest?')) {
        localStorage.removeItem('chatMessages')
        this.messages = []
      }
    },
    formatMessage (text) {
      if (!text) {
        return ''
      }

      const html = marked(text)
      const withTargetBlank = html.replace(/<a /g, '<a target="_blank" rel="noopener noreferrer" ')

      return withTargetBlank
    },
    formatTime (timestamp) {
      const date = new Date(timestamp)

      if (isNaN(date.getTime())) {
        console.error(`Invalid Date: ${timestamp}`)
        return 'Invalid Date'
      }

      return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })
    },
    scrollToBottom () {
      const container = this.$refs.messageContainer
      if (container) {
        container.scrollTop = container.scrollHeight
      }
    },
    showTypingAnimation () {
      this.isLoading = true
      setTimeout(() => {
        this.isLoading = false
      }, 2000)
    }
  }
}
</script>

<style scoped>
/* Allgemeiner Container für den Chat */
.chat-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 600px;
height: auto;
  background-color: #f0f4f8;
}

/* Das Chat-Fenster */
.chat-window {
  display: flex;
  flex-direction: column;
  height: 80vh;
  width: 80%;
  max-width: 400px;
  border-radius: 20px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  overflow: hidden;
  font-family: 'Inter', sans-serif !important;
}

/* Header des Chats */
.chat-header {
  background-color: #3b82f6;
  color: white;
  padding: 20px;
  text-align: center;
  font-weight: 600;
  font-size: 1.2rem;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* Nachrichtenbereich */
.messages {
  flex: 1;
  padding: 20px;
  overflow-y: auto;
  background-color: #ffffff;
}

/* Einzelne Nachrichten */
.message {
  margin-bottom: 20px;
}

/* Inhalt der Nachricht */
.message-content {
  display: inline-block;
  padding: 12px 16px;
  border-radius: 18px;
  max-width: 80%;
  font-family: 'Inter', sans-serif !important;
  font-size: 0.95rem !important;
  color: #333 !important;
  line-height: 1.4;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05);
  margin-bottom: 10px;
}

.message-content strong {
  font-weight: bold;
  color: #2563eb;
}

/* Links in den Nachrichten */
.message-content a {
  color: #1d4ed8 !important;
  text-decoration: none !important;
}

.message-content a:hover {
  text-decoration: underline !important;
}

/* Text und Links im Dark Mode */
.dark-mode .message-content {
  background-color: #374151;
  color: #e5e7eb !important;
}

.dark-mode .message-content a {
  color: #ffffff !important;
}

.dark-mode .message-content a:hover {
  color: #ffffff !important;
}

/* Zeilenumbrüche für Markdown-generierten Text */
.message-content p,
.message-content ul,
.message-content li {
  font-family: 'Inter', sans-serif !important;
  font-size: 0.95rem !important;
  color: #333 !important;
  line-height: 1.4;
  margin-bottom: 10px;
}

/* Benutzer- und Bot-Nachrichten */
.message.User {
  text-align: right;
}

.message.Bot {
  text-align: left;
}

.message.User .message-content {
  background-color: #3b82f6;
  color: white;
  border-bottom-right-radius: 4px;
}

.message.Bot .message-content {
  background-color: #f3f4f6;
  color: #1f2937;
  border-bottom-left-radius: 4px;
}

/* Zeitstempel der Nachricht */
.message-time {
  font-size: 0.75rem;
  color: #9ca3af;
  margin-top: 5px;
}

/* Eingabebereich */
.input-area {
  display: flex;
  align-items: center;
  padding: 15px;
  background-color: #f9fafb;
  border-top: 1px solid #e5e7eb;
}

input {
  flex: 1;
  padding: 12px 16px;
  border: 1px solid #d1d5db;
  border-radius: 25px;
  font-size: 0.95rem;
  outline: none;
  transition: border-color 0.2s, box-shadow 0.2s;
}

input:focus {
  border-color: #3b82f6;
  box-shadow: 0 0 0 3px rgba(59, 130, 246, 0.1);
}

button {
  background-color: #3b82f6;
  border: none;
  padding: 10px;
  margin-left: 10px;
  border-radius: 50%;
  color: white;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.2s;
}

button:hover {
  background-color: #2563eb;
}

.delete-icon {
  width: 20px;
  height: 20px;
  stroke: black; /* Standardfarbe */
}

.dark-mode .delete-icon {
  stroke: white; /* Weiße Farbe im Dark Mode */
}

.send-icon {
  width: 20px;
  height: 20px;
  fill: white;
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
}

.dot {
  width: 8px;
  height: 8px;
  background-color: #3b82f6;
  border-radius: 50%;
  margin: 0 4px;
  animation: bounce 1.4s infinite ease-in-out both;
}

.dot:nth-child(1) {
  animation-delay: -0.32s;
}

.dot:nth-child(2) {
  animation-delay: -0.16s;
}

/* Animation für die Ladeanzeige */
@keyframes bounce {
  0%,
  80%,
  100% {
    transform: scale(0);
  }
  40% {
    transform: scale(1);
  }
}

/* Dark Mode für das Chat-Fenster */
.dark-mode .chat-window {
  background-color: #1f2937;
  color: #e5e7eb;
}

.dark-mode .chat-header {
  background-color: #374151;
}

.dark-mode .messages {
  background-color: #1f2937; /* Dunkler Hintergrund im Dark Mode */
  color: #e5e7eb; /* Helle Textfarbe im Dark Mode */
}

.dark-mode .message-content {
  background-color: #374151; /* Dunkler Hintergrund für Nachrichten */
  color: #e5e7eb; /* Heller Text für Nachrichten */
}

.dark-mode .message.User .message-content {
  background-color: #3b82f6;
}

.dark-mode .input-area {
  background-color: #374151;
  border-top-color: #4b5563;
}

.dark-mode input {
  background-color: #4b5563;
  color: #e5e7eb;
  border-color: #6b7280;
}

/* Kopfzeilenaktionen */
.header-actions {
  display: flex;
  align-items: center;
}

.theme-toggle,
.clear-chat {
  background: none;
  border: none;
  color: white;
  cursor: pointer;
  margin-left: 10px;
}

.theme-toggle svg,
.clear-chat svg {
  width: 24px;
  height: 24px;
}

/* Datei-Upload */
.file-upload-label {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  background-color: #e5e7eb;
  border-radius: 50%;
  margin-right: 10px;
}

.file-upload-label svg {
  width: 20px;
  height: 20px;
  color: #4b5563;
}

/* Mobile Anpassungen */
@media (max-width: 480px) {
  .chat-window {
    width: 100%;
    min-height: 600px;
height: auto;
    border-radius: 0;
  }

  .header-actions {
    position: absolute;
    right: 10px;
    top: 10px;
  }
}
</style>
