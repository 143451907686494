<template>
  <div class="reason-when-slide slide-base"  data-step-name="Zeitpunkt">
    <div class="slide-content">
      <div class="slide-inner">
        <div class="header-section">
          <h1 class="slide-title" v-html="this.$prt.settings.text.reasonWhen"></h1>
        </div>

        <div class="main-section">
          <div class="timeline-grid">
            <button
              v-for="(option, index) in timelineOptions"
              :key="index"
              class="timeline-card"
              @click="next(option.title)"
            >
              <div class="icon-wrapper">
                <i :class="option.icon"></i>
              </div>
              <div class="option-content">
                <span class="option-title">{{ option.title }}</span>
                <span class="option-description">{{ option.description }}</span>
              </div>
              <span class="hover-arrow">→</span>
            </button>
          </div>
        </div>

        <div class="footer-section"></div>
      </div>
    </div>
  </div>
</template>

<script>
import slideMixin from '@/mixins/slideMixin'

export default {
  name: 'ReasonWhenSlide',
  mixins: [slideMixin],
  data () {
    return {
      timelineOptions: [
        {
          title: 'Schnellstmöglich',
          description: 'Innerhalb der nächsten Wochen',
          icon: 'fas fa-bolt'
        },
        {
          title: 'In den nächsten 6 Monaten',
          description: 'Mittelfristige Planung',
          icon: 'fas fa-calendar-alt'
        },
        {
          title: 'In den nächsten 2 Jahren',
          description: 'Langfristige Planung',
          icon: 'fas fa-hourglass-half'
        },
        {
          title: 'Später',
          description: 'Noch keine konkrete Planung',
          icon: 'fas fa-clock'
        }
      ]
    }
  },
  methods: {
    next (choice) {
      this.$store.commit('updateCollectedData', { reasonWhen: choice })
      this.$store.commit('nextSlide')
    },
    prev () {
      this.$store.commit('prevSlide')
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/css/_layout.scss';

.reason-when-slide {
  .header-section {
    text-align: center;
    margin-bottom: 2rem;

    .slide-title {
      font-size: clamp(1.5rem, 4vw, 2rem);
      color: var(--primary-color);
      font-weight: 600;
      line-height: 1.3;
    }
  }

  .timeline-grid {
    display: grid;
    gap: 1rem;
  }

  .timeline-card {
    position: relative;
    display: grid;
    grid-template-columns: auto 1fr auto;
    align-items: center;
    gap: 1rem;
    padding: 1.25rem;
    min-height: 44px;
    background: #f8fafc;
    border: 2px solid transparent;
    border-radius: 12px;
    cursor: pointer;
    transition: all 0.3s ease;
    text-align: left;

    &:hover {
      transform: translateY(-2px);
      border-color: var(--secondary-color);
      background: white;
      box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);

      .hover-arrow {
        opacity: 1;
        transform: translateX(0);
      }
    }

    .icon-wrapper {
      width: clamp(36px, 6vw, 48px);
      height: clamp(36px, 6vw, 48px);
      display: flex;
      align-items: center;
      justify-content: center;
      background: rgba(23, 181, 160, 0.1);
      border-radius: 50%;
      color: var(--secondary-color);
      flex-shrink: 0;
      font-size: clamp(1.25rem, 3vw, 1.5rem);
    }

    .option-content {
      display: flex;
      flex-direction: column;
      gap: 0.25rem;
    }

    .option-title {
      font-size: 1.1rem;
      color: var(--primary-color);
      font-weight: 500;
    }

    .option-description {
      font-size: 0.9rem;
      color: #666;
    }

    .hover-arrow {
      font-size: 1.25rem;
      color: var(--secondary-color);
      opacity: 0;
      transform: translateX(-10px);
      transition: all 0.3s ease;
    }
  }

  @media (max-width: 768px) {
    .header-section {
      padding: 1rem;
    }

    .main-section {
      padding: 1rem;
    }

    .timeline-card {
      padding: 1rem;

      .icon-wrapper {
        width: 36px;
        height: 36px;
        font-size: 1rem;
      }

      .option-title {
        font-size: 1rem;
      }

      .option-description {
        font-size: 0.85rem;
      }

      .hover-arrow {
        opacity: 1;
        transform: none;
      }
    }

    .footer-section {
      padding: 1rem;
      padding-bottom: calc(1rem + env(safe-area-inset-bottom, 0px));
    }
  }
}
</style>
