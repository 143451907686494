// Returns a function, that, as long as it continues to be invoked, will not
// be triggered. The function will be called after it stops being called for
// N milliseconds. If `immediate` is passed, trigger the function on the
// leading edge, instead of the trailing.
function debounce (func, wait, immediate) {
  let timeout
  return function () {
    let context = this
    let args = arguments
    let later = function () {
      timeout = null
      if (!immediate) func.apply(context, args)
    }
    let callNow = immediate && !timeout
    clearTimeout(timeout)
    timeout = setTimeout(later, wait)
    if (callNow) func.apply(context, args)
  }
}

function isCurrentSlideActive (elm) {
  return elm.closest('.swiper-slide').classList.contains('swiper-slide-active')
}

function onActiveSlide (elm, callback) {
  if (isCurrentSlideActive(elm)) {
    callback()
  }
}

function formatMoney (amount, decimalCount = 2, decimal = ',', thousands = '.') {
  try {
    decimalCount = Math.abs(decimalCount)
    decimalCount = isNaN(decimalCount) ? 2 : decimalCount

    const negativeSign = amount < 0 ? '-' : ''

    let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString()
    let j = (i.length > 3) ? i.length % 3 : 0

    return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + thousands) + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : '')
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log(e)
  }
}

function setCookie (name, value, hours) {
  let expires = ''
  if (hours) {
    let date = new Date()
    date.setTime(date.getTime() + (hours * 60 * 60 * 1000))
    expires = '; expires=' + date.toUTCString()
  }
  document.cookie = name + '=' + (value || '') + expires + '; path=/'
}
function getCookie (name) {
  var nameEQ = name + '='
  var ca = document.cookie.split(';')
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i]
    while (c.charAt(0) === ' ') c = c.substring(1, c.length)
    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length)
  }
  return null
}
function eraseCookie (name) {
  document.cookie = name + '=; Max-Age=-99999999;'
}

const getSafe = fn => {
  try { return fn() } catch (e) {}
}

const getAllLeadTypes = () => {
  return ['APARTMENT', 'HOUSE', 'LAND', 'APARTMENT_HOUSE', 'RENT_APARTMENT', 'RENT_HOUSE', 'BUSINESS']
}

export default {
  debounce,
  onActiveSlide,
  isCurrentSlideActive,
  formatMoney,
  setCookie,
  getCookie,
  eraseCookie,
  getSafe,
  getAllLeadTypes
}
