var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"developed-property-slide slide-base",attrs:{"data-step-name":"Erschlossen"}},[_c('div',{staticClass:"slide-content"},[_c('div',{staticClass:"slide-inner"},[_c('div',{staticClass:"main-section"},[_c('div',{staticClass:"header-section"},[_c('h1',{staticClass:"slide-title",domProps:{"innerHTML":_vm._s(_vm.title)}}),_c('p',{staticClass:"slide-description"},[_vm._v("Wie ist der Erschließungszustand des Grundstücks?")])]),_c('div',{staticClass:"options-grid"},[_c('button',{staticClass:"option-card",on:{"click":function($event){return _vm.next('Erschlossen')}}},[_vm._m(0),_vm._m(1),_c('span',{staticClass:"hover-arrow"},[_vm._v("→")])]),_c('button',{staticClass:"option-card",on:{"click":function($event){return _vm.next('Teilerschlossen')}}},[_vm._m(2),_vm._m(3),_c('span',{staticClass:"hover-arrow"},[_vm._v("→")])]),_c('button',{staticClass:"option-card",on:{"click":function($event){return _vm.next('Nicht erschlossen')}}},[_vm._m(4),_vm._m(5),_c('span',{staticClass:"hover-arrow"},[_vm._v("→")])])])]),_c('div',{staticClass:"footer-section"},[_c('div',{staticClass:"navigation-buttons"},[_c('button',{staticClass:"nav-button prev-button",on:{"click":_vm.prev}},[_c('i',{staticClass:"button-icon fas fa-arrow-left"})])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"icon-container"},[_c('i',{staticClass:"far fa-check-circle"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"option-content"},[_c('span',{staticClass:"option-title"},[_vm._v("Erschlossen")]),_c('span',{staticClass:"option-description"},[_vm._v("Alle Anschlüsse und Zugänge vorhanden")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"icon-container"},[_c('i',{staticClass:"fas fa-adjust"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"option-content"},[_c('span',{staticClass:"option-title"},[_vm._v("Teilerschlossen")]),_c('span',{staticClass:"option-description"},[_vm._v("Einige Anschlüsse bereits vorhanden")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"icon-container"},[_c('i',{staticClass:"far fa-times-circle"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"option-content"},[_c('span',{staticClass:"option-title"},[_vm._v("Nicht erschlossen")]),_c('span',{staticClass:"option-description"},[_vm._v("Keine Anschlüsse vorhanden")])])
}]

export { render, staticRenderFns }