<template>
  <div class="floor-count-slide slide-base"  data-step-name="Etagenanzahl">
    <div class="slide-content">
      <div class="slide-inner">
        <div class="main-section">
          <!-- Header Section -->
          <div class="header-section">
            <h1 class="slide-title" v-html="title"></h1>
            <p class="slide-description">
              Wie viele Etagen hat Ihr Haus?
            </p>
          </div>

          <!-- Floor Count Grid -->
          <div class="floor-grid">
            <button
              v-for="count in floorCounts"
              :key="count.value"
              class="floor-card"
              @click="next(count.value)"
            >
              <div class="icon-container">
                <component :is="count.icon"/>
              </div>
              <span class="floor-label">{{ count.label }}</span>
              <span class="hover-arrow">→</span>
            </button>
          </div>

          <div class="footer-section">
            <div class="navigation-buttons">
              <button class="nav-button prev-button" @click="prev">
                <i class="button-icon fas fa-arrow-left"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Floor1 from '@/assets/icons/floor-1.svg'
import Floor2 from '@/assets/icons/floor-2.svg'
import Floor3 from '@/assets/icons/floor-3.svg'
import Floor4 from '@/assets/icons/floor-4.svg'
import slideMixin from '@/mixins/slideMixin'

export default {
  name: 'FloorCountSlide',
  mixins: [slideMixin],
  computed: {
    isTransitioning () {
      return this.$store.state.isTransitioning
    }
  },
  components: {
    Floor1,
    Floor2,
    Floor3,
    Floor4
  },
  props: {
    title: String
  },
  data () {
    return {
      floorCounts: [
        { value: 1, label: '1 Etage', icon: 'Floor1' },
        { value: 2, label: '2 Etagen', icon: 'Floor2' },
        { value: 3, label: '3 Etagen', icon: 'Floor3' },
        { value: 4, label: '4 Etagen', icon: 'Floor4' }
      ]
    }
  },
  methods: {
    next (choice) {
      this.$store.commit('updateCollectedData', { floor: choice })
      this.$store.commit('nextSlide')
    },
    prev () {
      this.$store.commit('prevSlide')
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/css/_layout.scss';

.floor-count-slide {
  .floor-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
    gap: 1.5rem;
  }

  .floor-card {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    padding: 1.5rem;
    background: #f8fafc;
    border: 2px solid transparent;
    border-radius: 12px;
    cursor: pointer;
    transition: all 0.3s ease;
    overflow: hidden;
    min-height: 44px;

    &:hover {
      transform: translateY(-4px);
      border-color: var(--secondary-color);
      background: white;
      box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);

      .hover-arrow {
        opacity: 1;
        transform: translateX(0);
      }

      .icon-container {
        background: rgba(23, 181, 160, 0.1);

        svg {
          color: var(--secondary-color);
        }
      }
    }

    .icon-container {
      width: 64px;
      height: 64px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #f1f5f9;
      border-radius: 16px;
      transition: all 0.3s ease;

      svg {
        width: 32px;
        height: 32px;
        color: #64748b;
        transition: all 0.3s ease;
      }
    }

    .floor-label {
      font-size: 1rem;
      color: var(--primary-color);
      font-weight: 500;
      text-align: center;
    }

    .hover-arrow {
      position: absolute;
      right: 1rem;
      bottom: 1rem;
      font-size: 1.25rem;
      color: var(--secondary-color);
      opacity: 0;
      transform: translateX(-10px);
      transition: all 0.3s ease;
    }
  }

  .header-section {
    text-align: center;
    margin-bottom: 2rem;

    .slide-title {
      font-size: clamp(1.5rem, 4vw, 2rem);
      color: var(--primary-color);
      font-weight: 600;
      margin-bottom: 0.5rem;
      line-height: 1.3;
    }

    .slide-description {
      font-size: 1.1rem;
      color: #666;
      margin: 0;
    }
  }

  @media (max-width: 768px) {
    .main-section {
      padding: 1rem;
    }

    .floor-grid {
      grid-template-columns: repeat(2, 1fr);
      gap: 1rem;
    }

    .floor-card {
      flex-direction: row;
      justify-content: flex-start;
      padding: 1rem;
      text-align: left;

      .icon-container {
        width: 48px;
        height: 48px;

        svg {
          width: 24px;
          height: 24px;
        }
      }

      .floor-label {
        font-size: 0.9rem;
        text-align: left;
      }

      .hover-arrow {
        display: none;
      }
    }

    .footer-section {
      padding: 1rem;

      .navigation-buttons {
        flex-direction: column;

        .nav-button {
          width: 100%;
          justify-content: center;
        }
      }
    }
  }
}
</style>
