<template>
  <div class="floor-slide slide-base"  data-step-name="Etage">
    <div class="slide-content">
      <div class="slide-inner">
        <div class="main-section">
          <!-- Header Section -->
          <div class="header-section">
            <h1 class="slide-title" v-html="title"></h1>
            <p class="slide-description">
              Wählen Sie die Etage Ihrer Immobilie
            </p>
          </div>

          <!-- Floor Selection Grid -->
          <div class="floor-grid">
            <!-- First Row -->
            <div class="floor-row">
              <button
                class="floor-card"
                @click="next(0)"
                :title="'Untergeschoss auswählen'"
              >
                <div class="icon-container">
                  <IconFloorMinus1/>
                </div>
                <span class="floor-label">Untergeschoss</span>
              </button>

              <button
                class="floor-card"
                @click="next(0)"
                :title="'Erdgeschoss auswählen'"
              >
                <div class="icon-container">
                  <IconFloor0/>
                </div>
                <span class="floor-label">Erdgeschoss</span>
              </button>

              <button
                class="floor-card"
                @click="next(1)"
                :title="'1. Stock auswählen'"
              >
                <div class="icon-container">
                  <IconFloor1/>
                </div>
                <span class="floor-label">1. Stock</span>
              </button>

              <button
                class="floor-card"
                @click="next(2)"
                :title="'2. Stock auswählen'"
              >
                <div class="icon-container">
                  <IconFloor2/>
                </div>
                <span class="floor-label">2. Stock</span>
              </button>
            </div>

            <!-- Second Row -->
            <div class="floor-row">
              <button
                class="floor-card"
                @click="next(3)"
                :title="'3. Stock auswählen'"
              >
                <div class="icon-container">
                  <IconFloor3/>
                </div>
                <span class="floor-label">3. Stock</span>
              </button>

              <button
                class="floor-card"
                @click="next(4)"
                :title="'4. Stock auswählen'"
              >
                <div class="icon-container">
                  <IconFloor4/>
                </div>
                <span class="floor-label">4. Stock</span>
              </button>

              <button
                class="floor-card"
                @click="next(5)"
                :title="'5. Stock auswählen'"
              >
                <div class="icon-container">
                  <IconFloor5/>
                </div>
                <span class="floor-label">5. Stock</span>
              </button>

              <button
                class="floor-card"
                @click="next(6)"
                :title="'6. Stock oder höher auswählen'"
              >
                <div class="icon-container">
                  <IconFloor6/>
                </div>
                <span class="floor-label">6. Stock oder höher</span>
              </button>
            </div>
          </div>
        </div>

        <div class="footer-section">
          <div class="navigation-buttons">
            <button class="nav-button prev-button" @click="prev">
              <i class="button-icon fas fa-arrow-left"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import IconFloorMinus1 from '@/assets/icons/floor-minus-1.svg'
import IconFloor0 from '@/assets/icons/floor-0.svg'
import IconFloor1 from '@/assets/icons/floor-1.svg'
import IconFloor2 from '@/assets/icons/floor-2.svg'
import IconFloor3 from '@/assets/icons/floor-3.svg'
import IconFloor4 from '@/assets/icons/floor-4.svg'
import IconFloor5 from '@/assets/icons/floor-5.svg'
import IconFloor6 from '@/assets/icons/floor-6.svg'
import slideMixin from '@/mixins/slideMixin'

export default {
  name: 'FloorSlide',
  mixins: [slideMixin],
  computed: {
    isTransitioning () {
      return this.$store.state.isTransitioning
    }
  },
  components: {
    IconFloorMinus1,
    IconFloor0,
    IconFloor1,
    IconFloor2,
    IconFloor3,
    IconFloor4,
    IconFloor5,
    IconFloor6
  },
  props: {
    title: String
  },
  methods: {
    prev () {
      if (!this.isTransitioning) {
        this.$store.commit('prevSlide')
      }
    },
    next (floor) {
      // Validierung hinzufügen
      if (floor >= 0 && floor <= 100) {
        this.$store.commit('updateCollectedData', { floor })
        this.$store.commit('nextSlide')
      } else {
        console.error('Ungültiger Etagen-Wert:', floor)
      }
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/css/_layout.scss';

.floor-slide {
  .slide-content {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .slide-inner {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 1rem;
    max-width: 1200px;
    margin: 0 auto;
    width: 100%;
  }

  .main-section {
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  .header-section {
    text-align: center;
    margin-bottom: 2rem;

    .slide-title {
      font-size: 1.75rem;
      color: var(--primary-color);
      font-weight: 600;
      margin-bottom: 0.75rem;
      line-height: 1.2;
    }

    .slide-description {
      font-size: 1.1rem;
      color: #666;
      margin: 0;
    }
  }

  .floor-grid {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    max-width: 800px;
    margin: 0 auto;
    width: 100%;
  }

  .floor-row {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
  }

  .floor-card {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0.75rem;
    padding: 1rem;
    background: #fff;
    border: 1px solid #e2e8f0;
    border-radius: 8px;
    cursor: pointer;
    transition: all 0.2s ease;
    height: 100%;
    min-height: 120px;

    &:hover {
      transform: translateY(-2px);
      border-color: var(--secondary-color);
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
    }

    &:active {
      transform: translateY(0);
    }

    .icon-container {
      width: 48px;
      height: 48px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #f8fafc;
      border-radius: 8px;
      transition: all 0.2s ease;

      svg {
        width: 24px;
        height: 24px;
        color: #64748b;
      }
    }

    .floor-label {
      font-size: 0.9rem;
      color: var(--primary-color);
      font-weight: 500;
      text-align: center;
      line-height: 1.2;
      margin: 0;
    }
  }

  .footer-section {
    margin-top: 2rem;

    .navigation-buttons {
      display: flex;
      justify-content: space-between;
      gap: 1rem;
    }

    .nav-button {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0.75rem 1.5rem;
      border: none;
      border-radius: 8px;
      background: var(--primary-color);
      color: white;
      cursor: pointer;
      transition: all 0.2s ease;

      &:hover {
        background: darken(#17b5a0, 5%);
      }

      .button-icon {
        margin-right: 0.5rem;
      }
    }
  }

  @media (max-width: 768px) {
    .slide-inner {
      padding: 1rem 0.5rem;
    }

    .header-section {
      margin-bottom: 1.5rem;
      padding: 0 0.5rem;

      .slide-title {
        font-size: 1.5rem;
      }

      .slide-description {
        font-size: 1rem;
      }
    }

    .floor-grid {
      padding: 0 0.5rem;
    }

    .floor-row {
      grid-template-columns: repeat(2, 1fr);
      gap: 0.75rem;
    }

    .floor-card {
      min-height: 100px;
      padding: 0.75rem;

      .icon-container {
        width: 40px;
        height: 40px;

        svg {
          width: 20px;
          height: 20px;
        }
      }

      .floor-label {
        font-size: 0.875rem;
      }
    }

    .footer-section {
      margin-top: 1.5rem;
      padding: 0 0.5rem;

      .nav-button {
        padding: 0.5rem 1rem;
      }
    }
  }

  @media (max-width: 480px) {
    .floor-card {
      min-height: 90px;
      padding: 0.5rem;

      .icon-container {
        width: 36px;
        height: 36px;

        svg {
          width: 18px;
          height: 18px;
        }
      }

      .floor-label {
        font-size: 0.8rem;
      }
    }
  }
}
</style>
