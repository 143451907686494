<template>
  <div class="extra-slide slide-base"  data-step-name="Extra Step">
    <div class="slide-content">
      <div class="slide-inner">
        <div class="main-section">
          <!-- Header Section -->
          <div class="header-section">
            <!-- Custom HTML Content wird hier eingefügt -->
            <div class="content-section" v-html="this.$prt.settings.extraSlide"/>
          </div>

          <!-- Navigation -->
          <div class="navigation-section">
            <button @click="next" class="next-button">
              Weiter
              <span class="button-icon">→</span>
            </button>
          </div>
        </div>

        <div class="footer-section"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import commons from '@/commons'
import slideMixin from '@/mixins/slideMixin'

export default {
  name: 'ExtraSlide',
  mixins: [slideMixin],
  computed: mapState(['currentSlide']),
  isTransitioning () {
    return this.$store.state.isTransitioning
  },
  methods: {
    next () {
      this.$store.commit('nextSlide')
    },
    onActiveSlide () {
      if (commons.isCurrentSlideActive(this.$el)) {
        if (this.$prt.settings.extraSlideRoute) {
          window.history.pushState(null, null, '/' + this.$prt.settings.extraSlideRoute)
        }
      }
    }
  },
  watch: {
    currentSlide () {
      this.onActiveSlide()
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/css/_layout.scss';

.extra-slide {
  .content-section {
    margin-bottom: 2rem;
    color: var(--primary-color);

    // Styling für mögliche HTML-Elemente im Content
    h1, h2, h3 {
      margin-bottom: 1rem;
      font-weight: 600;
      line-height: 1.3;
    }

    h1 {
      font-size: clamp(1.5rem, 4vw, 2rem);
      color: var(--primary-color);
    }

    h2 {
      font-size: clamp(1.25rem, 3vw, 1.5rem);
    }

    h3 {
      font-size: clamp(1.1rem, 2.5vw, 1.25rem);
    }

    p {
      margin-bottom: 1rem;
      line-height: 1.6;
      color: #4a5568;
      font-size: clamp(0.9rem, 2vw, 1rem);
    }

    ul, ol {
      margin-bottom: 1rem;
      padding-left: 1.5rem;

      li {
        margin-bottom: 0.5rem;
        color: #4a5568;
      }
    }

    a {
      color: var(--secondary-color);
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .navigation-section {
    display: flex;
    justify-content: flex-end;
  }

  .next-button {
    display: inline-flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.875rem 1.5rem;
    background: var(--secondary-color);
    color: white;
    border: none;
    border-radius: 12px;
    font-size: 1rem;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.3s ease;
    min-height: 44px;

    &:hover {
      background: var(--secondary-color-dark);
      transform: translateY(-2px);

      .button-icon {
        transform: translateX(4px);
      }
    }

    &:active {
      transform: translateY(0);
    }

    .button-icon {
      font-size: 1.25rem;
      transition: transform 0.3s ease;
    }
  }

  @media (max-width: 768px) {
    .main-section {
      padding: 1rem;
    }

    .content-section {
      margin-bottom: 1.5rem;
    }

    .next-button {
      width: 100%;
      justify-content: center;
      padding: 1rem;
    }
  }
}
</style>
