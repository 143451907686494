<template>
  <div class="maps-slide slide-base"  data-step-name="Adresse">
    <div class="slide-content">
      <div class="slide-inner">
        <div class="main-section">
          <!-- Header ist nun Teil des scrollbaren Bereichs -->
          <div class="header-section">
            <h1 class="slide-title" v-html="title"></h1>
            <p class="slide-description">Bitte geben Sie die Adresse der Immobilie ein</p>
          </div>

          <!-- Form Section -->
          <div class="form-container">
            <div class="input-container">
              <input
                type="text"
                class="main-address-input"
                ref="autocompleteInput"
                placeholder="z.B. Invaliden Straße 1, Berlin"
                @input="handleAutocompleteInput"
                aria-label="Adresse eingeben"
              />
            </div>

            <div class="address-grid">
              <div class="form-group">
                <label for="street">Straße</label>
                <input
                  id="street"
                  type="text"
                  v-model="manualAddress.street"
                  @input="manualUpdateAddressField('street', $event.target.value)"
                  autocomplete="address-line1"
                  class="form-input"
                />
              </div>

              <div class="form-group">
                <label for="streetNumber">Hausnr.</label>
                <input
                  id="streetNumber"
                  type="text"
                  v-model="manualAddress.streetNumber"
                  @input="manualUpdateAddressField('streetNumber', $event.target.value)"
                  autocomplete="address-line2"
                  class="form-input"
                />
              </div>

              <div class="form-group">
                <label for="postalCode">PLZ</label>
                <input
                  id="postalCode"
                  type="text"
                  v-model="manualAddress.postalCode"
                  @input="manualUpdateAddressField('postalCode', $event.target.value)"
                  autocomplete="postal-code"
                  class="form-input"
                />
              </div>

              <div class="form-group">
                <label for="locality">Ort</label>
                <input
                  id="locality"
                  type="text"
                  v-model="manualAddress.locality"
                  @input="manualUpdateAddressField('locality', $event.target.value)"
                  autocomplete="address-level2"
                  class="form-input"
                />
              </div>
            </div>

            <div class="map-wrapper">
              <gmap-map
                ref="gmap"
                :center="center"
                :zoom="zoom"
                :options="gmapOptions"
                class="map"
              >
                <gmap-marker
                  v-for="(marker, index) in markers"
                  :key="index"
                  :position="marker.position"
                ></gmap-marker>
              </gmap-map>
            </div>
          </div>
        </div>

        <div class="footer-section">
          <div class="navigation-buttons">
            <button class="nav-button prev-button" @click="prev">
              <i class="button-icon fas fa-arrow-left"></i>
            </button>
            <button class="nav-button next-button" @click="next">
              <i class="button-icon fas fa-arrow-right"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import slideMixin from '@/mixins/slideMixin'

export default {
  name: 'MapsSlide',
  mixins: [slideMixin],
  computed: {
    isTransitioning () {
      return this.$store.state.isTransitioning
    }
  },
  props: {
    title: String
  },
  data () {
    return {
      manualAddress: {
        street: '',
        streetNumber: '',
        postalCode: '',
        locality: ''
      },
      center: { lat: 51.1657, lng: 10.4515 },
      zoom: 6,
      markers: [],
      gmapOptions: {
        disableDefaultUI: true,
        zoomControl: true,
        styles: [
          {
            featureType: 'all',
            elementType: 'all',
            stylers: [
              { saturation: -100 },
              { lightness: 20 }
            ]
          }
        ]
      }
    }
  },
  methods: {
    setZoomForAddress (place) {
      if (place.geometry && place.geometry.viewport) {
        this.$refs.gmap.fitBounds(place.geometry.viewport)
      } else {
        this.zoom = 16
        this.center = {
          lat: place.geometry.location.lat(),
          lng: place.geometry.location.lng()
        }
      }
    },
    validateInput () {
      const { street, streetNumber, postalCode, locality } = this.manualAddress
      if (!street || !streetNumber || !postalCode || !locality) {
        this.$modal.show('dialog', {
          title: 'Unvollständige Adresse',
          text: 'Bitte füllen Sie alle Adressfelder aus.',
          buttons: [{ title: 'OK' }]
        })
        return false
      }
      return true
    },
    prev () {
      if (!this.isTransitioning) {
        this.$store.commit('prevSlide')
      }
    },
    next () {
      if (this.validateInput()) {
        const formattedAddress = `${this.manualAddress.street} ${this.manualAddress.streetNumber}, ${this.manualAddress.postalCode} ${this.manualAddress.locality}`
        this.$store.commit('updateCollectedData', { address: formattedAddress })
        this.$store.commit('nextSlide')
      }
    },
    handleAutocompleteInput () {
      const autocomplete = new google.maps.places.Autocomplete(this.$refs.autocompleteInput, {
        componentRestrictions: { country: 'de' }
      })

      autocomplete.addListener('place_changed', () => {
        const place = autocomplete.getPlace()
        if (place.geometry) {
          this.setZoomForAddress(place)
          this.markers = [{
            position: {
              lat: place.geometry.location.lat(),
              lng: place.geometry.location.lng()
            }
          }]
          this.manualAddress = this.extractAddressComponents(place)
        }
      })
    },
    extractAddressComponents (place) {
      const components = {}
      for (const component of place.address_components) {
        const types = component.types
        if (types.includes('route')) {
          components.street = component.long_name
        }
        if (types.includes('street_number')) {
          components.streetNumber = component.long_name
        }
        if (types.includes('postal_code')) {
          components.postalCode = component.long_name
        }
        if (types.includes('locality')) {
          components.locality = component.long_name
        }
      }
      return components
    },
    manualUpdateAddressField (field, value) {
      this.manualAddress[field] = value
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/css/_layout.scss';

.maps-slide {
  .header-section {
    padding-top: 4rem;
    text-align: center;
  }
  .form-container {
    .input-container {
      margin-bottom: 1.5rem;
    }

    .main-address-input {
      width: 100%;
      padding: 1rem;
      font-size: 1rem;
      border: 2px solid #e0e0e0;
      border-radius: 12px;
      transition: all 0.3s ease;
      min-height: 44px;

      &:focus {
        border-color: var(--secondary-color);
        box-shadow: 0 0 0 3px rgba(23, 181, 160, 0.1);
        outline: none;
      }
    }
  }

  .navigation-buttons {
    display: flex;
    gap: 1rem;
    margin-top: 1rem;

    .nav-button {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      padding: 0.875rem 1.5rem;
      border: none;
      border-radius: 12px;
      font-size: 1rem;
      font-weight: 600;
      cursor: pointer;
      transition: all 0.3s ease;
      min-height: 44px;

      &.prev-button {
        background: var(--button-prev-color);
        color: white;

        &:hover {
          transform: translateY(-2px);
          box-shadow: 0 4px 12px rgba(252, 92, 101, 0.2);
        }

        .button-icon {
          margin-right: 0.5rem;
        }
      }

      &.next-button {
        background: var(--button-next-color);
        color: white;

        &:hover {
          transform: translateY(-2px);
          box-shadow: 0 4px 12px rgba(32, 191, 107, 0.2);
        }

        .button-icon {
          margin-left: 0.5rem;
        }
      }
    }

    .button-icon {
      font-size: 1.25rem;
      transition: transform 0.3s ease;
    }
  }

  .address-grid {
    display: grid;
    grid-template-columns: 3fr 1fr;
    gap: 1rem;
    margin-bottom: 1.5rem;

    .form-group {
      &:nth-child(3), &:nth-child(4) {
        grid-column: span 1;
      }

      label {
        display: block;
        font-size: 0.9rem;
        color: #666;
        margin-bottom: 0.5rem;
        font-weight: 500;
      }

      .form-input {
        width: 100%;
        padding: 0.75rem;
        font-size: 0.95rem;
        border: 2px solid #e0e0e0;
        border-radius: 8px;
        transition: all 0.3s ease;
        min-height: 44px;

        &:focus {
          border-color: var(--secondary-color);
          box-shadow: 0 0 0 3px rgba(23, 181, 160, 0.1);
          outline: none;
        }
      }
    }
  }

  .map-wrapper {
    position: relative;
    height: 300px;
    border-radius: 12px;
    overflow: hidden;
    margin-bottom: 1.5rem;

    .map {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 12px;
    }
  }

  @media (max-width: 768px) {
    .address-grid {
      grid-template-columns: 1fr;

      .form-group {
        grid-column: span 1 !important;
      }
    }

    .map-wrapper {
      height: 250px;
    }
    .navigation-buttons {
      flex-direction: row; // Behält die horizontale Ausrichtung auch auf Mobile
      justify-content: space-between;

      .nav-button {
        // Buttons behalten ihre Größe
        width: 44px;
        height: 44px;
      }
    }
  }
}
</style>
