<template>
  <div class="construction-year-slide slide-base"  data-step-name="Baujahr">
    <div class="slide-content">
      <div class="slide-inner">
        <div class="main-section">
          <!-- Header Section -->
          <div class="header-section">
            <h1 class="slide-title" v-html="title"></h1>
            <div class="year-badge">
              <ConstructionYearIcon class="year-icon"/>
              <span class="current-value">Baujahr {{ constructionYear }}</span>
            </div>
          </div>

          <!-- Year Selection Section -->
          <div class="selection-section">
            <!-- Desktop/Tablet Slider -->
            <div class="desktop-tablet-only">
              <RangeSlideWrapper
                unit=""
                prt-key="constructionYear"
                v-model="constructionYear"
                :min="1900"
                :max="2024"
                :label-every="25"
                :show-min-label="true"
                :plus-on-max="false"
                :custom-marks="customMarks"
              >
                <template v-slot:icon>
                  <ConstructionYearIcon/>
                </template>
              </RangeSlideWrapper>
            </div>

            <!-- Mobile Quick Selection -->
            <div class="mobile-only">
              <div class="quick-select">
                <button
                  v-for="year in quickSelectValues"
                  :key="year"
                  class="quick-select-btn"
                  :class="{ active: constructionYear === year }"
                  @click="selectValue(year)"
                >
                  {{ year }}
                </button>
              </div>
            </div>

            <!-- Help Text -->
            <div class="help-text">
              <i class="far fa-info-circle"></i>
              <span>Das Baujahr finden Sie in Ihren Unterlagen oder fragen Sie den Eigentümer. Eine ungefähre Angabe ist ausreichend.</span>
            </div>

            <!-- Time Periods -->
            <div class="time-periods">
              <div class="period-item" :class="{ active: isPeriod('Altbau') }">
                <span class="period-label">Altbau</span>
                <span class="period-years">bis 1960</span>
              </div>
              <div class="period-item" :class="{ active: isPeriod('Bestandsbau') }">
                <span class="period-label">Bestandsbau</span>
                <span class="period-years">1960 - 1990</span>
              </div>
              <div class="period-item" :class="{ active: isPeriod('Postmodern') }">
                <span class="period-label">Postmodern</span>
                <span class="period-years">1990 - 2010</span>
              </div>
              <div class="period-item" :class="{ active: isPeriod('Neubau') }">
                <span class="period-label">Neubau</span>
                <span class="period-years">ab 2021</span>
              </div>
            </div>
          </div>
        </div>

        <!-- Navigation Buttons -->
        <div class="footer-section">
          <div class="navigation-buttons">
            <button type="button" class="nav-button prev-button" @click="prevSlide">
              <i class="button-icon fas fa-arrow-left"></i>
            </button>
            <button type="button" class="nav-button next-button" @click="nextSlide">
              <i class="button-icon fas fa-arrow-right"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ConstructionYearIcon from '@/assets/icons/construction-year.svg'
import RangeSlideWrapper from '@/components/RangeSlideWrapper'
import slideMixin from '@/mixins/slideMixin'

export default {
  name: 'ConstructionYearSlide',
  mixins: [slideMixin],
  components: {
    RangeSlideWrapper,
    ConstructionYearIcon
  },
  props: {
    title: String
  },
  data () {
    return {
      quickSelectValues: [1970, 1990, 2000, 2010, 2020, 2024]
    }
  },
  computed: {
    isTransitioning () {
      return this.$store.state.isTransitioning
    },
    constructionYear: {
      get () {
        return this.$store.state.collectedData.constructionYear || 1980
      },
      set (value) {
        this.updateValue(value)
      }
    },
    customMarks () {
      return {
        1900: '1900',
        1925: '1925',
        1950: '1950',
        1975: '1975',
        2000: '2000',
        2024: '2024'
      }
    }
  },
  methods: {
    updateValue (value = this.constructionYear) {
      this.$store.commit('updateCollectedData', { constructionYear: value })
    },
    selectValue (value) {
      this.constructionYear = value
    },
    prevSlide () {
      this.updateValue()
      this.$store.commit('prevSlide')
    },
    nextSlide () {
      this.updateValue()
      this.$store.commit('nextSlide')
    },
    isPeriod (period) {
      const year = this.constructionYear
      switch (period) {
        case 'Altbau':
          return year <= 1960
        case 'Bestandsbau':
          return year > 1960 && year <= 1990
        case 'Postmodern':
          return year > 1990 && year <= 2010
        case 'Neubau':
          return year > 2010
        default:
          return false
      }
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/css/_layout.scss';

.construction-year-slide {
  .selection-section {
    margin-bottom: 2rem;
  }

  .year-badge {
    display: inline-flex;
    align-items: center;
    padding: 0.75rem 1.5rem;
    background: rgba(23, 181, 160, 0.1);
    border-radius: 100px;
    gap: 0.75rem;

    .year-icon {
      width: 24px;
      height: 24px;
      color: var(--secondary-color);
    }

    .current-value {
      font-size: 1.25rem;
      font-weight: 600;
      color: var(--secondary-color);
    }
  }

  .quick-select {
    display: flex;
    flex-wrap: wrap;
    gap: 0.75rem;
    justify-content: center;
    margin: 1.5rem 0;

    .quick-select-btn {
      min-height: 44px;
      padding: 0.5rem 1rem;
      border: 2px solid #e0e0e0;
      border-radius: 8px;
      background: white;
      color: #666;
      font-size: 0.95rem;
      font-weight: 500;
      cursor: pointer;
      transition: all 0.3s ease;

      &:hover {
        border-color: var(--secondary-color);
        color: var(--secondary-color);
        transform: translateY(-2px);
      }

      &.active {
        background: var(--secondary-color);
        border-color: var(--secondary-color);
        color: white;
      }
    }
  }

  .help-text {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    color: #666;
    font-size: 0.9rem;
    padding: 1rem;
    background: #f8fafc;
    border-radius: 12px;
    margin-top: 1rem;

    i {
      color: var(--secondary-color);
      font-size: 1.1rem;
    }
  }

  .time-periods {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    gap: 1rem;
    margin-top: 1.5rem;

    .period-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 1rem;
      background: #f8fafc;
      border-radius: 12px;
      transition: all 0.3s ease;
      opacity: 0.6;
      min-height: 44px;

      &.active {
        opacity: 1;
        background: rgba(23, 181, 160, 0.1);

        .period-label {
          color: var(--secondary-color);
        }
      }

      .period-label {
        font-weight: 600;
        color: var(--primary-color);
        margin-bottom: 0.25rem;
      }

      .period-years {
        font-size: 0.85rem;
        color: #666;
      }
    }
  }

  @media (max-width: 768px) {
    .quick-select {
      .quick-select-btn {
        flex: 1;
        min-width: calc(33.33% - 0.75rem);
      }
    }
  }
}
</style>
