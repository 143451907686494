<template>
  <div class="extra-step-slide slide-base"  :data-step-name="'Extra: ' + title">
    <div class="slide-content">
      <div class="slide-inner">
        <div class="main-section">
          <!-- Header Section -->
          <div class="header-section">
            <h1 class="slide-title" v-html="title"></h1>
          </div>

          <!-- Options Grid -->
          <div class="options-grid">
            <button
              v-for="(answer, index) in answers"
              :key="index"
              class="option-card"
              @click="next(answer)"
            >
              <div class="icon-wrapper">
                <i class="far fa-question-circle"></i>
              </div>
              <span class="option-text">{{ answer.answer }}</span>
              <span class="hover-arrow">→</span>
            </button>
          </div>
        </div>

        <div class="footer-section">
          <div class="navigation-buttons">
            <button class="nav-button prev-button" @click="prev">
              <i class="button-icon fas fa-arrow-left"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import slideMixin from '@/mixins/slideMixin'

export default {
  name: 'ExtraStepSlide',
  mixins: [slideMixin],
  computed: {
    ...mapState(['extraSteps']),
    isTransitioning () {
      return this.$store.state.isTransitioning
    }
  },
  props: {
    index: Number,
    title: String,
    answers: Array
  },
  methods: {
    next (val) {
      const extraSteps = [...this.extraSteps]
      extraSteps[this.index] = {
        ...val,
        order: this.index,
        title: this.title
      }
      this.$store.commit('setExtraSteps', extraSteps)
      this.$store.commit('nextSlide')
    },
    prev () {
      if (!this.isTransitioning) {
        this.$store.commit('prevSlide')
      }
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/css/_layout.scss';

.extra-step-slide {
  .header-section {
    text-align: center;
    margin-bottom: 2rem;

    .slide-title {
      font-size: clamp(1.5rem, 4vw, 2rem);
      color: var(--primary-color);
      font-weight: 600;
      line-height: 1.3;
    }
  }

  .options-grid {
    display: grid;
    gap: 1rem;
  }

  .option-card {
    position: relative;
    display: grid;
    grid-template-columns: auto 1fr auto;
    align-items: center;
    gap: 1rem;
    padding: 1.25rem;
    min-height: 44px;
    background: #f8fafc;
    border: 2px solid transparent;
    border-radius: 12px;
    cursor: pointer;
    transition: all 0.3s ease;
    text-align: left;

    &:hover {
      transform: translateY(-2px);
      border-color: var(--secondary-color);
      background: white;
      box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);

      .hover-arrow {
        opacity: 1;
        transform: translateX(0);
      }

      .icon-wrapper {
        background: rgba(23, 181, 160, 0.1);
        color: var(--secondary-color);
      }
    }

    .icon-wrapper {
      width: clamp(36px, 6vw, 48px);
      height: clamp(36px, 6vw, 48px);
      display: flex;
      align-items: center;
      justify-content: center;
      background: #f1f5f9;
      border-radius: 50%;
      color: #64748b;
      font-size: clamp(1.25rem, 3vw, 1.5rem);
      transition: all 0.3s ease;
    }

    .option-text {
      font-size: clamp(1rem, 2.5vw, 1.1rem);
      color: var(--primary-color);
      font-weight: 500;
    }

    .hover-arrow {
      font-size: 1.25rem;
      color: var(--secondary-color);
      opacity: 0;
      transform: translateX(-10px);
      transition: all 0.3s ease;
    }
  }

  @media (max-width: 768px) {
    .main-section {
      padding: 1rem;
    }

    .option-card {
      padding: 1rem;

      .icon-wrapper {
        width: 36px;
        height: 36px;
        font-size: 1rem;
      }

      .option-text {
        font-size: 1rem;
      }

      .hover-arrow {
        opacity: 1;
        transform: none;
      }
    }

    .footer-section {
      padding: 1rem;

      .navigation-buttons {
        flex-direction: column;

        .nav-button {
          width: 100%;
          justify-content: center;
        }
      }
    }
  }
}
</style>
