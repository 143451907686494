<template>
  <div style="width: inherit">
    <button @click="$emit('click')" :class="computedType" :type="submit ? 'submit' : 'button'">
      <slot>Next</slot>
    </button>
  </div>
</template>

<script>
export default {
  name: 'FancyButton',
  props: {
    type: {
      type: String,
      required: true,
      default: 'next',
      validator: (value) => {
        return ['prev', 'next', 'finish'].indexOf(value) !== -1
      }
    },
    submit: Boolean
  },
  computed: {
    computedType () {
      let hvr = this.type === 'prev' ? 'hvr-bounce-to-left' : 'hvr-bounce-to-right'
      return ['prt-button', 'prt-fancy-button', hvr, this.type]
    }
  }
}
</script>

<style scoped>
.prt-button {
  border: 0;
  cursor: pointer;
  padding: 6px 16px;
  font-size: 21px;
  color: #303030;
  background-color: #f0f0f0;
  outline: none !important;
  border-radius: 0;
  font-weight: 400;
  text-transform: none;
}

.prt-fancy-button {
  display: block;
  vertical-align: middle;
  width: inherit;
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0,0,0,0);
  position: relative;
  transition-property: color;
  transition-duration: .5s;
}

/* Prev */
.prt-button.prev:hover::before,
.prt-button.prev:before,
.prt-button.prt-red:hover::before,
.prt-button.prt-red:before {
  background-color: var(--button-prev-color);
}

/* Next */
.prt-button.next:hover::before,
.prt-button.next:before,
.prt-button.prt-green:hover::before,
.prt-button.prt-green:before {
  background-color: var(--button-next-color);
}

/* Finish */
.prt-button.finish:hover::before,
.prt-button.finish:before {
  background-color: var(--button-finish-color);
}

@media (max-width: 768px) {
  /* Ausblenden der FancyButtons auf mobilen Geräten */
  .prt-fancy-button {
    display: none;
  }
}
</style>
