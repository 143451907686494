<template>
  <div class="house-type-slide slide-base"  data-step-name="Haustyp">
    <div class="slide-content">
      <div class="slide-inner">
        <div class="main-section">
          <!-- Header Section -->
          <div class="header-section">
            <h1 class="slide-title" v-html="title"></h1>
            <p class="slide-description">
              Wählen Sie die Art Ihres Hauses aus
            </p>
          </div>

          <!-- House Type Grid -->
          <div class="house-type-grid">
            <button
              class="type-card"
              @click="next('Einfamilienhaus')"
            >
              <div class="icon-container">
                <EinfamilienhausIcon/>
              </div>
              <span class="type-label">Einfamilienhaus</span>
              <span class="hover-arrow">→</span>
            </button>

            <button
              class="type-card"
              @click="next('Mehrfamilienhaus')"
            >
              <div class="icon-container">
                <MehrfamilienhausIcon/>
              </div>
              <span class="type-label">Mehrfamilienhaus</span>
              <span class="hover-arrow">→</span>
            </button>

            <button
              class="type-card"
              @click="next('Reihenhaus')"
            >
              <div class="icon-container">
                <ReihenhausIcon/>
              </div>
              <span class="type-label">Reihenhaus</span>
              <span class="hover-arrow">→</span>
            </button>

            <button
              class="type-card"
              @click="next('Doppelhaushälfte')"
            >
              <div class="icon-container">
                <DoppelhaushaelfteIcon/>
              </div>
              <span class="type-label">Doppelhaushälfte</span>
              <span class="hover-arrow">→</span>
            </button>
          </div>

          <!-- Mobile Back Button -->
          <div class="footer-section">
            <div class="navigation-buttons">
              <button class="nav-button prev-button" @click="prev">
                <i class="button-icon fas fa-arrow-left"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import EinfamilienhausIcon from '@/assets/icons/einfamilienhaus.svg'
import MehrfamilienhausIcon from '@/assets/icons/mehrfamilienhaus.svg'
import ReihenhausIcon from '@/assets/icons/reihenhaus.svg'
import DoppelhaushaelfteIcon from '@/assets/icons/doppelhaushaelfte.svg'
import slideMixin from '@/mixins/slideMixin'

export default {
  name: 'HouseTypeSlide',
  mixins: [slideMixin],
  components: {
    EinfamilienhausIcon,
    MehrfamilienhausIcon,
    ReihenhausIcon,
    DoppelhaushaelfteIcon
  },
  props: {
    title: String
  },
  methods: {
    next (choice) {
      this.$store.commit('updateCollectedData', { houseType: choice })
      this.$store.commit('nextSlide')
    },
    prev () {
      this.$store.commit('prevSlide')
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/css/_layout.scss';

.house-type-slide {
  .header-section {
    text-align: center;
    margin-bottom: 2rem;

    .slide-title {
      font-size: clamp(1.5rem, 4vw, 2rem);
      color: var(--primary-color);
      font-weight: 600;
      margin-bottom: 0.5rem;
      line-height: 1.3;
    }

    .slide-description {
      font-size: 1.1rem;
      color: #666;
      margin: 0;
    }
  }

  .house-type-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 1.5rem;
    margin-bottom: 2rem;
  }

  .type-card {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    padding: 1.5rem;
    background: #f8fafc;
    border: 2px solid transparent;
    border-radius: 12px;
    cursor: pointer;
    transition: all 0.3s ease;
    overflow: hidden;
    min-height: 44px;

    &:hover {
      transform: translateY(-4px);
      border-color: var(--secondary-color);
      background: white;
      box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);

      .hover-arrow {
        opacity: 1;
        transform: translateX(0);
      }

      .icon-container {
        background: rgba(23, 181, 160, 0.1);

        svg {
          color: var(--secondary-color);
        }
      }
    }

    .icon-container {
      width: 64px;
      height: 64px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #f1f5f9;
      border-radius: 16px;
      transition: all 0.3s ease;

      svg {
        width: 32px;
        height: 32px;
        color: #64748b;
        transition: all 0.3s ease;
      }
    }

    .type-label {
      font-size: 1rem;
      color: var(--primary-color);
      font-weight: 500;
      text-align: center;
    }

    .hover-arrow {
      position: absolute;
      right: 1rem;
      bottom: 1rem;
      font-size: 1.25rem;
      color: var(--secondary-color);
      opacity: 0;
      transform: translateX(-10px);
      transition: all 0.3s ease;
    }
  }

  @media (max-width: 768px) {
    .main-section {
      padding: 1rem;
    }

    .house-type-grid {
      grid-template-columns: 1fr;
      gap: 1rem;
    }

    .type-card {
      flex-direction: row;
      justify-content: flex-start;
      padding: 1rem;
      text-align: left;

      .icon-container {
        width: 48px;
        height: 48px;

        svg {
          width: 24px;
          height: 24px;
        }
      }

      .type-label {
        font-size: 0.95rem;
        text-align: left;
      }

      .hover-arrow {
        display: none;
      }
    }

    .show-on-mobile {
      display: block !important;
    }
  }
}
</style>
