<template>
  <div class="site-area-slide slide-base"  data-step-name="Grundfläche">
    <div class="slide-content">
      <div class="slide-inner">
        <div class="main-section">
          <!-- Header Section -->
          <div class="header-section">
            <h1 class="slide-title" v-html="title"></h1>
            <div class="area-badge">
              <GrundstucksflacheIcon class="area-icon"/>
              <span class="current-value">{{ siteArea }} m²</span>
            </div>
          </div>

          <!-- Area Selection Section -->
          <div class="selection-section">
            <!-- Help Text -->
            <div class="help-text">
              <i class="far fa-info-circle"></i>
              <span>Die Grundstücksfläche finden Sie in Ihrem Grundbuchauszug oder den Hausdokumenten.</span>
            </div>

            <!-- Desktop/Tablet Slider -->
            <div class="desktop-tablet-only">
              <RangeSlideWrapper
                unit=" m²"
                prt-key="siteArea"
                v-model="siteArea"
                :min="200"
                :max="3000"
                :label-every="1000"
                :show-min-label="true"
              >
                <template v-slot:icon>
                  <GrundstucksflacheIcon/>
                </template>
              </RangeSlideWrapper>
            </div>

            <!-- Mobile Quick Selection -->
            <div class="mobile-only">
              <!-- Custom Input Field -->
              <div class="custom-input-wrapper">
                <div class="input-group">
                  <input
                    type="number"
                    v-model.number="localSiteArea"
                    class="area-input"
                    placeholder="200"
                    @blur="validateInput"
                    @input="handleInput"
                  >
                  <span class="unit">m²</span>
                </div>
              </div>

              <!-- Quick Selection Buttons -->
              <div class="quick-select">
                <button
                  v-for="value in quickSelectValues"
                  :key="value"
                  class="quick-select-btn"
                  :class="{ active: siteArea === value }"
                  @click="selectValue(value)"
                >
                  {{ value }}m²
                </button>
              </div>
            </div>
          </div>
        </div>

        <!-- Footer Section -->
        <div class="footer-section">
          <div class="navigation-buttons">
            <button class="nav-button prev-button" @click="prevSlide">
              <i class="button-icon fas fa-arrow-left"></i>
            </button>
            <button class="nav-button next-button" @click="nextSlide">
              <i class="button-icon fas fa-arrow-right"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import GrundstucksflacheIcon from '@/assets/icons/grundstuecksflaeche-haus.svg'
import RangeSlideWrapper from '@/components/RangeSlideWrapper'
import slideMixin from '@/mixins/slideMixin'

export default {
  name: 'SiteAreaSlide',
  mixins: [slideMixin],
  components: {
    RangeSlideWrapper,
    GrundstucksflacheIcon
  },
  props: {
    title: String
  },
  data () {
    return {
      quickSelectValues: [300, 500, 750, 1000, 1500],
      localSiteArea: null // Lokaler Zustand für das Input-Feld
    }
  },
  computed: {
    isTransitioning () {
      return this.$store.state.isTransitioning
    },
    siteArea: {
      get () {
        // Stellen Sie sicher, dass immer ein Nummer zurückgegeben wird
        const value = this.$store.state.collectedData.siteArea
        return value !== null && value !== undefined ? Number(value) : 200 // Default zu 200
      },
      set (value) {
        this.updateValue(value)
      }
    },
    displayValue () {
      // Formatierte Anzeige für das Badge
      return this.siteArea || 200
    }
  },
  methods: {
    updateValue (value) {
      if (value !== null && !isNaN(value)) {
        this.$store.commit('updateCollectedData', { siteArea: Number(value) })
      }
    },
    handleInput (event) {
      const value = event.target.value
      if (value === '') {
        this.localSiteArea = null
      } else {
        this.localSiteArea = Number(value)
      }
    },
    validateInput () {
      let value = this.localSiteArea

      // Wenn das Feld leer ist oder der Wert zu klein ist, setze auf Minimum
      if (value === null || value < 200) {
        value = 200
      } else if (value > 3000) {
        value = 3000
      }

      this.localSiteArea = value
      this.siteArea = value
    },
    selectValue (value) {
      this.localSiteArea = value
      this.siteArea = value
    },
    prevSlide () {
      this.validateInput()
      this.$store.commit('prevSlide')
    },
    nextSlide () {
      this.validateInput()
      this.$store.commit('nextSlide')
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/css/_layout.scss';

.site-area-slide {
  .selection-section {
    margin-bottom: 2rem;

    .help-text {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      color: #666;
      font-size: 0.9rem;
      padding: 1rem;
      background: #f8fafc;
      border-radius: 12px;
      margin-bottom: 1.5rem;

      i {
        color: var(--secondary-color);
        font-size: 1.1rem;
      }
    }
  }

  .custom-input-wrapper {
    margin: 1rem 0;

    .input-group {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 0.5rem;

      .area-input {
        width: 120px;
        height: 48px;
        padding: 0.5rem;
        border: 2px solid #e0e0e0;
        border-radius: 8px;
        font-size: 1.1rem;
        text-align: center;
        color: var(--secondary-color);
        font-weight: 500;
        min-height: 44px;

        &:focus {
          outline: none;
          border-color: var(--secondary-color);
        }

        &::-webkit-inner-spin-button,
        &::-webkit-outer-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
      }

      .unit {
        font-size: 1.1rem;
        color: #666;
        font-weight: 500;
      }
    }
  }

  .quick-select {
    display: flex;
    flex-wrap: wrap;
    gap: 0.75rem;
    justify-content: center;
    margin: 1.5rem 0;

    .quick-select-btn {
      min-height: 44px;
      padding: 0.5rem 1rem;
      border: 2px solid #e0e0e0;
      border-radius: 8px;
      background: white;
      color: #666;
      font-size: 1.1rem;
      font-weight: 500;
      cursor: pointer;
      transition: all 0.3s ease;

      &:hover {
        border-color: var(--secondary-color);
        color: var(--secondary-color);
        transform: translateY(-2px);
      }

      &.active {
        background: var(--secondary-color);
        border-color: var(--secondary-color);
        color: white;
      }
    }
  }

  .area-badge {
    display: inline-flex;
    align-items: center;
    padding: 0.75rem 1.5rem;
    background: rgba(23, 181, 160, 0.1);
    border-radius: 100px;
    gap: 0.75rem;

    .area-icon {
      width: 24px;
      height: 24px;
      color: var(--secondary-color);
    }

    .current-value {
      font-size: 1.25rem;
      font-weight: 600;
      color: var(--secondary-color);
    }
  }

  @media (max-width: 768px) {
    .quick-select {
      .quick-select-btn {
        flex: 1;
        min-width: calc(33.33% - 0.75rem);
      }
    }
  }
}
</style>
