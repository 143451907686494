<template>
  <div class="reason-slide slide-base"  data-step-name="Grund">
    <div class="slide-content">
      <div class="slide-inner">
        <div class="header-section">
          <h1 class="slide-title" v-html="$prt?.settings?.text?.reason || 'Aus welchem Grund möchten Sie eine Bewertung?'"></h1>        </div>

        <div class="main-section">
          <div class="reason-grid" v-if="reasons.length > 0">
            <button
              v-for="reason in reasons"
              :key="reason"
              class="reason-card"
              @click="next(reason)"
            >
              <div class="icon-wrapper">
                <i class="far fa-question-circle" aria-hidden="true"></i>
              </div>
              <span class="reason-text">{{ reason }}</span>
              <span class="hover-arrow" aria-hidden="true">→</span>
            </button>
          </div>
          <div v-else class="no-reasons-message">
            Keine Auswahlmöglichkeiten verfügbar
          </div>
        </div>

        <div class="footer-section"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import slideMixin from '@/mixins/slideMixin'

export default {
  name: 'ReasonSlide',
  mixins: [slideMixin],
  computed: {
    ...mapState(['collectedData']),
    isTransitioning () {
      return this.$store.state.isTransitioning
    },
    reasons () {
      // Überprüfung und Fallback
      if (!this.$prt?.settings) {
        console.warn('$prt.settings ist nicht verfügbar')
        return []
      }

      const settings = this.$prt.settings
      const reasonList = this.collectedData.type === 'rent'
        ? settings.reasonsWhenRenting
        : settings.reasonsWhenBuying

      if (!reasonList) {
        console.warn(`Keine Gründe gefunden für Typ: ${this.collectedData.type}`)
        return []
      }

      return reasonList
    }
  },
  methods: {
    next (reason) {
      this.$store.commit('updateCollectedData', { reason })
      this.$store.commit('nextSlide')
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/css/_layout.scss';

.reason-slide {
  .header-section {
    flex: 0 0 auto;
    text-align: center;
    padding: 1.5rem;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    z-index: 2;
    background: #fff;

    .slide-title {
      font-size: clamp(1.5rem, 4vw, 2rem);
      color: var(--primary-color);
      font-weight: 600;
      line-height: 1.3;
    }
  }

  .reason-grid {
    display: grid;
    gap: 1rem;
  }

  .reason-card {
    position: relative;
    display: grid;
    grid-template-columns: auto 1fr auto;
    align-items: center;
    gap: 1rem;
    padding: 1.25rem;
    min-height: 44px;
    background: #f8fafc;
    border: 2px solid transparent;
    border-radius: 12px;
    cursor: pointer;
    transition: all 0.3s ease;
    text-align: left;

    &:hover {
      transform: translateY(-2px);
      border-color: var(--secondary-color);
      background: white;
      box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);

      .hover-arrow {
        opacity: 1;
        transform: translateX(0);
      }
    }

    .icon-wrapper {
      width: clamp(36px, 6vw, 48px);
      height: clamp(36px, 6vw, 48px);
      display: flex;
      align-items: center;
      justify-content: center;
      background: rgba(23, 181, 160, 0.1);
      border-radius: 50%;
      color: var(--secondary-color);
      flex-shrink: 0;
      font-size: clamp(1.25rem, 3vw, 1.5rem);
    }

    .reason-text {
      font-size: clamp(1rem, 2.5vw, 1.1rem);
      color: var(--primary-color);
      font-weight: 500;
    }

    .hover-arrow {
      font-size: 1.25rem;
      color: var(--secondary-color);
      opacity: 0;
      transform: translateX(-10px);
      transition: all 0.3s ease;
    }
  }

  @media (max-width: 768px) {
    .main-section {
      padding: 1rem;
    }

    .reason-card {
      padding: 1rem;

      .icon-wrapper {
        width: 36px;
        height: 36px;
        font-size: 1rem;
      }

      .reason-text {
        font-size: 1rem;
      }

      .hover-arrow {
        opacity: 1;
        transform: none;
      }
    }

    .footer-section {
      padding: 1rem;
      padding-bottom: calc(1rem + env(safe-area-inset-bottom, 0px));
    }
  }
}
</style>
