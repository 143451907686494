<template>
  <div class="rent-type-slide slide-base"  data-step-name="Typ">
    <div class="slide-content">
      <div class="slide-inner">
        <div class="main-section">
          <!-- Header Section -->
          <div class="header-section">
            <h1 class="slide-title" v-html="title"></h1>
            <p class="slide-description">Wählen Sie die Art der Mietimmobilie</p>
          </div>

          <!-- Options Grid -->
          <div class="options-grid">
            <button
              class="option-card"
              @click="next('APARTMENT')"
            >
              <div class="icon-container">
                <WohnungIcon/>
              </div>
              <div class="option-content">
                <span class="option-title">Wohnung</span>
                <span class="option-description">Wohneinheit in einem Mehrfamilienhaus</span>
              </div>
              <span class="hover-arrow">→</span>
            </button>

            <button
              class="option-card"
              @click="next('HOUSE')"
            >
              <div class="icon-container">
                <HausIcon/>
              </div>
              <div class="option-content">
                <span class="option-title">Haus</span>
                <span class="option-description">Freistehendes Haus oder Reihenhaus</span>
              </div>
              <span class="hover-arrow">→</span>
            </button>
          </div>

          <!-- Mobile Back Button -->
          <div class="footer-section">
            <div class="navigation-buttons">
              <button class="nav-button prev-button" @click="prev">
                <i class="button-icon fas fa-arrow-left"></i>
              </button>
            </div>
          </div>

          <div class="footer-section"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import WohnungIcon from '@/assets/icons/wohnung.svg'
import HausIcon from '@/assets/icons/haus.svg'
import slideMixin from '@/mixins/slideMixin'

export default {
  name: 'RentTypeSlide',
  mixins: [slideMixin],
  computed: {
    isTransitioning () {
      return this.$store.state.isTransitioning
    }
  },
  components: {
    WohnungIcon,
    HausIcon
  },
  props: {
    title: String
  },
  methods: {
    next (choice) {
      this.$store.commit('updateCollectedData', {
        rentType: choice,
        type: 'RENT_' + choice
      })
      this.$store.commit('nextSlide')
    },
    prev () {
      this.$store.commit('prevSlide')
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/css/_layout.scss';

.rent-type-slide {
  .header-section {
    text-align: center;
    margin-bottom: 2rem;

    .slide-title {
      font-size: clamp(1.5rem, 4vw, 2rem);
      color: var(--primary-color);
      font-weight: 600;
      margin-bottom: 0.5rem;
      line-height: 1.3;
    }

    .slide-description {
      font-size: 1.1rem;
      color: #666;
      margin: 0;
    }
  }

  .options-grid {
    display: grid;
    gap: 1.5rem;
    margin-bottom: 2rem;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  }

  .option-card {
    position: relative;
    display: grid;
    grid-template-columns: 80px 1fr auto;
    align-items: center;
    gap: 1.25rem;
    padding: 1.5rem;
    background: #f8fafc;
    border: 2px solid transparent;
    border-radius: 12px;
    cursor: pointer;
    transition: all 0.3s ease;
    text-align: left;
    width: 100%;
    min-height: 44px;

    &:hover {
      transform: translateY(-2px);
      border-color: var(--secondary-color);
      background: white;
      box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);

      .hover-arrow {
        opacity: 1;
        transform: translateX(0);
      }

      .icon-container {
        background: rgba(23, 181, 160, 0.1);

        svg {
          color: var(--secondary-color);
        }
      }
    }

    .icon-container {
      width: 64px;
      height: 64px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #f1f5f9;
      border-radius: 16px;
      transition: all 0.3s ease;
      padding: 1rem;

      svg {
        width: 100%;
        height: 100%;
        color: #64748b;
        transition: all 0.3s ease;
      }
    }

    .option-content {
      display: flex;
      flex-direction: column;
      gap: 0.25rem;
      flex: 1;
      min-width: 0;
    }

    .option-title {
      font-size: 1.1rem;
      color: var(--primary-color);
      font-weight: 500;
    }

    .option-description {
      font-size: 0.9rem;
      color: #666;
      line-height: 1.4;
    }

    .hover-arrow {
      font-size: 1.25rem;
      color: var(--secondary-color);
      opacity: 0;
      transform: translateX(-10px);
      transition: all 0.3s ease;
      padding-left: 0.5rem;
    }
  }

  // Mobile Navigation
  .mobile-nav {
    margin-top: 2rem;
    padding-top: 1rem;
    border-top: 1px solid #eee;
    display: none;

    .prev-button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      padding: 1rem;
      background: var(--button-prev-color);
      color: white;
      border: none;
      border-radius: 12px;
      font-size: 1rem;
      font-weight: 600;
      cursor: pointer;
      transition: all 0.3s ease;
      gap: 0.5rem;
      min-height: 44px;

      .button-icon {
        font-size: 1.25rem;
      }

      &:hover {
        background: scale-color(#fc5c65, $lightness: -5%);
        transform: translateY(-1px);
      }
    }
  }

  .footer-section {
    flex: 0 0 auto;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    background: #fff;
  }

  @media (max-width: 768px) {
    .main-section {
      padding: 1rem;

      .header-section {
        margin-bottom: 1.5rem;

        .slide-title {
          font-size: 1.5rem;
        }

        .slide-description {
          font-size: 1rem;
        }
      }

      .options-grid {
        grid-template-columns: 1fr;
        gap: 1rem;
      }

      .option-card {
        grid-template-columns: 60px 1fr auto;
        padding: 1.25rem;
        gap: 1rem;

        .icon-container {
          width: 48px;
          height: 48px;
          padding: 0.75rem;
        }

        .option-title {
          font-size: 1rem;
        }

        .option-description {
          font-size: 0.85rem;
        }

        .hover-arrow {
          display: none;
        }
      }
    }

    .show-on-mobile {
      display: block !important;
    }
  }

  // Tablet Optimierungen
  @media (min-width: 769px) and (max-width: 1024px) {
    .option-card {
      grid-template-columns: 70px 1fr auto;
    }
  }
}
</style>
