<template>
  <div class="rent-category-slide slide-base"  data-step-name="Mietkategorie">
    <div class="slide-content">
      <div class="slide-inner">
        <div class="main-section">
          <!-- Header Section -->
          <div class="header-section">
            <h1 class="slide-title" v-html="title"></h1>
            <p class="slide-description">Wählen Sie die passende Kategorie für Ihre Immobilie</p>
          </div>

          <!-- Options Grid -->
          <div class="options-grid">
            <button
              v-for="option in filteredOptions"
              :key="option.value"
              class="option-card"
              @click="next(option.value)"
            >
              <div class="icon-container">
                <i :class="option.icon"></i>
              </div>
              <div class="option-content">
                <span class="option-title">{{ option.label }}</span>
                <span class="option-description">{{ option.description }}</span>
              </div>
              <span class="hover-arrow">→</span>
            </button>
          </div>
        </div>

        <div class="footer-section">
          <div class="navigation-buttons">
            <button class="nav-button prev-button" @click="prev">
              <i class="button-icon fas fa-arrow-left"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import slideMixin from '@/mixins/slideMixin'

export default {
  name: 'RentCategorySlide',
  mixins: [slideMixin],
  props: {
    title: String
  },
  computed: {
    ...mapState(['collectedData']),
    isTransitioning () {
      return this.$store.state.isTransitioning
    },
    filteredOptions () {
      const baseOptions = [
        {
          value: '0-Keine Informationen',
          label: 'Keine Informationen',
          description: 'Keine spezifischen Details bekannt',
          icon: 'fas fa-question-circle',
          type: 'all'
        },
        // Wohnungsoptionen
        {
          value: '3-Dachgeschoss',
          label: 'Dachgeschoss',
          description: 'Wohnung im obersten Stockwerk',
          icon: 'fas fa-home',
          type: 'APARTMENT'
        },
        {
          value: '7-Maisonette',
          label: 'Maisonette',
          description: 'Wohnung über mehrere Etagen',
          icon: 'fas fa-level-up-alt',
          type: 'APARTMENT'
        },
        {
          value: '38-Eigentumswohnung',
          label: 'Eigentumswohnung',
          description: 'Separate Wohneinheit im Mehrfamilienhaus',
          icon: 'fas fa-building',
          type: 'APARTMENT'
        },
        // Hausoptionen
        {
          value: '15-Bungalow',
          label: 'Bungalow',
          description: 'Ebenerdiges Wohnhaus',
          icon: 'fas fa-home',
          type: 'HOUSE'
        },
        {
          value: '17-Doppelhaushälfte',
          label: 'Doppelhaushälfte',
          description: 'Eine Hälfte eines Zweifamilienhauses',
          icon: 'fas fa-building',
          type: 'HOUSE'
        },
        {
          value: '18-Einfamilienhaus',
          label: 'Einfamilienhaus',
          description: 'Freistehendes Haus für eine Familie',
          icon: 'fas fa-house',
          type: 'HOUSE'
        },
        {
          value: '25-Reihenhaus',
          label: 'Reihenhaus',
          description: 'Haus in einer Reihenhausanlage',
          icon: 'fas fa-city',
          type: 'HOUSE'
        }
      ]

      return baseOptions.filter(option =>
        option.type === 'all' || option.type === this.collectedData.rentType
      )
    }
  },
  methods: {
    next (value) {
      const [id] = value.split('-')
      this.$store.commit('updateCollectedData', {
        rentCategory: value,
        objectCategory: parseInt(id)
      })
      this.$store.commit('nextSlide')
    },
    prev () {
      if (!this.isTransitioning) {
        this.$store.commit('prevSlide')
      }
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/css/_layout.scss';

.rent-category-slide {
  .header-section {
    text-align: center;
    margin-bottom: 2rem;

    .slide-title {
      font-size: clamp(1.5rem, 4vw, 2rem);
      color: var(--primary-color);
      font-weight: 600;
      margin-bottom: 0.5rem;
      line-height: 1.3;
    }

    .slide-description {
      font-size: 1.1rem;
      color: #666;
      margin: 0;
    }
  }

  .options-grid {
    display: grid;
    gap: 1rem;
  }

  .option-card {
    position: relative;
    display: grid;
    grid-template-columns: 80px 1fr auto;
    align-items: center;
    gap: 1.25rem;
    padding: 1.5rem;
    background: #f8fafc;
    border: 2px solid transparent;
    border-radius: 12px;
    cursor: pointer;
    transition: all 0.3s ease;
    text-align: left;
    width: 100%;
    min-height: 44px;

    &:hover {
      transform: translateY(-2px);
      border-color: var(--secondary-color);
      background: white;
      box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);

      .hover-arrow {
        opacity: 1;
        transform: translateX(0);
      }

      .icon-container {
        background: rgba(23, 181, 160, 0.1);

        i {
          color: var(--secondary-color);
        }
      }
    }

    .icon-container {
      width: 64px;
      height: 64px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #f1f5f9;
      border-radius: 16px;
      transition: all 0.3s ease;

      i {
        font-size: 28px;
        color: #64748b;
        transition: all 0.3s ease;
      }
    }

    .option-content {
      display: flex;
      flex-direction: column;
      gap: 0.25rem;
      flex: 1;
      min-width: 0;
    }

    .option-title {
      font-size: 1.1rem;
      color: var(--primary-color);
      font-weight: 500;
    }

    .option-description {
      font-size: 0.9rem;
      color: #666;
      line-height: 1.4;
    }

    .hover-arrow {
      font-size: 1.25rem;
      color: var(--secondary-color);
      opacity: 0;
      transform: translateX(-10px);
      transition: all 0.3s ease;
      padding-left: 0.5rem;
    }
  }

  @media (max-width: 768px) {
    .main-section {
      padding: 1rem;
    }

    .option-card {
      grid-template-columns: 60px 1fr auto;
      padding: 1.25rem;
      gap: 1rem;

      .icon-container {
        width: 48px;
        height: 48px;

        i {
          font-size: 24px;
        }
      }

      .option-title {
        font-size: 1rem;
      }

      .option-description {
        font-size: 0.85rem;
      }

      .hover-arrow {
        display: none;
      }
    }

    .footer-section {
      .navigation-buttons {
        flex-direction: column;

        .nav-button {
          width: 100%;
          justify-content: center;
        }
      }
    }
  }
}
</style>
