var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"construction-possibilities-slide slide-base",attrs:{"data-step-name":"Bebauungsmöglichkeiten"}},[_c('div',{staticClass:"slide-content"},[_c('div',{staticClass:"slide-inner"},[_c('div',{staticClass:"main-section"},[_c('div',{staticClass:"header-section"},[_c('h1',{staticClass:"slide-title",domProps:{"innerHTML":_vm._s(_vm.title)}}),_c('p',{staticClass:"slide-description"},[_vm._v("Welche Bebauungsmöglichkeiten bestehen für das Grundstück?")])]),_c('div',{staticClass:"options-grid"},[_c('button',{staticClass:"option-card",on:{"click":function($event){return _vm.next('Kurzfristig')}}},[_vm._m(0),_vm._m(1),_c('span',{staticClass:"hover-arrow"},[_vm._v("→")])]),_c('button',{staticClass:"option-card",on:{"click":function($event){return _vm.next('Eingeschränkt')}}},[_vm._m(2),_vm._m(3),_c('span',{staticClass:"hover-arrow"},[_vm._v("→")])]),_c('button',{staticClass:"option-card",on:{"click":function($event){return _vm.next('Nicht bebaubar')}}},[_vm._m(4),_vm._m(5),_c('span',{staticClass:"hover-arrow"},[_vm._v("→")])]),_c('button',{staticClass:"option-card",on:{"click":function($event){return _vm.next('Weiß nicht')}}},[_vm._m(6),_vm._m(7),_c('span',{staticClass:"hover-arrow"},[_vm._v("→")])])])]),_c('div',{staticClass:"footer-section"},[_c('div',{staticClass:"navigation-buttons"},[_c('button',{staticClass:"nav-button prev-button",on:{"click":_vm.prev}},[_c('i',{staticClass:"button-icon fas fa-arrow-left"})])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"icon-container"},[_c('i',{staticClass:"fas fa-border-all"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"option-content"},[_c('span',{staticClass:"option-title"},[_vm._v("Kurzfristig")]),_c('span',{staticClass:"option-description"},[_vm._v("Sofortige Bebauung möglich")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"icon-container"},[_c('i',{staticClass:"far fa-exclamation-circle"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"option-content"},[_c('span',{staticClass:"option-title"},[_vm._v("Eingeschränkt")]),_c('span',{staticClass:"option-description"},[_vm._v("Bebauung mit Auflagen möglich")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"icon-container"},[_c('i',{staticClass:"fas fa-ban"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"option-content"},[_c('span',{staticClass:"option-title"},[_vm._v("Nicht bebaubar")]),_c('span',{staticClass:"option-description"},[_vm._v("Keine Bebauung möglich")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"icon-container"},[_c('i',{staticClass:"far fa-question-circle"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"option-content"},[_c('span',{staticClass:"option-title"},[_vm._v("Weiß nicht")]),_c('span',{staticClass:"option-description"},[_vm._v("Keine Informationen vorhanden")])])
}]

export { render, staticRenderFns }