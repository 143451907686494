var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"site-layout-slide slide-base",attrs:{"data-step-name":"Zuschnitt"}},[_c('div',{staticClass:"slide-content"},[_c('div',{staticClass:"slide-inner"},[_c('div',{staticClass:"main-section"},[_c('div',{staticClass:"header-section"},[_c('h1',{staticClass:"slide-title",domProps:{"innerHTML":_vm._s(_vm.title)}}),_c('p',{staticClass:"slide-description"},[_vm._v("Welche Form hat Ihr Grundstück?")])]),_c('div',{staticClass:"options-grid"},[_c('button',{staticClass:"option-card",on:{"click":function($event){return _vm.next('Eckgrundstück')}}},[_vm._m(0),_vm._m(1),_c('span',{staticClass:"hover-arrow"},[_vm._v("→")])]),_c('button',{staticClass:"option-card",on:{"click":function($event){return _vm.next('Rechteckig')}}},[_vm._m(2),_vm._m(3),_c('span',{staticClass:"hover-arrow"},[_vm._v("→")])]),_c('button',{staticClass:"option-card",on:{"click":function($event){return _vm.next('Sonstiges')}}},[_vm._m(4),_vm._m(5),_c('span',{staticClass:"hover-arrow"},[_vm._v("→")])])])]),_c('div',{staticClass:"footer-section"},[_c('div',{staticClass:"navigation-buttons"},[_c('button',{staticClass:"nav-button prev-button",on:{"click":_vm.prev}},[_c('i',{staticClass:"button-icon fas fa-arrow-left"})])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"icon-container"},[_c('i',{staticClass:"fas fa-border-all"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"option-content"},[_c('span',{staticClass:"option-title"},[_vm._v("Eckgrundstück")]),_c('span',{staticClass:"option-description"},[_vm._v("Liegt an zwei kreuzenden Straßen")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"icon-container"},[_c('i',{staticClass:"fas fa-border-all"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"option-content"},[_c('span',{staticClass:"option-title"},[_vm._v("Rechteckig")]),_c('span',{staticClass:"option-description"},[_vm._v("Regelmäßige, rechteckige Form")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"icon-container"},[_c('i',{staticClass:"fas fa-vector-square"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"option-content"},[_c('span',{staticClass:"option-title"},[_vm._v("Sonstiges")]),_c('span',{staticClass:"option-description"},[_vm._v("Andere Form oder unregelmäßiger Zuschnitt")])])
}]

export { render, staticRenderFns }