<template>
  <div class="room-count-slide slide-base"  data-step-name="Zimmer">
    <div class="slide-content">
      <div class="slide-inner">
        <div class="main-section">
          <!-- Header Section -->
          <div class="header-section">
            <h1 class="slide-title" v-html="title"></h1>
            <div class="room-badge">
              <RoomIcon class="room-icon"/>
              <span class="current-value">{{ rooms }} {{ rooms === 1 ? 'Zimmer' : 'Zimmer' }}</span>
            </div>
          </div>

          <!-- Room Selection Section -->
          <div class="selection-section">
            <!-- Desktop/Tablet Slider -->
            <div class="desktop-tablet-only">
              <RangeSlideWrapper
                unit=""
                prt-key="rooms"
                v-model="rooms"
                :min="1"
                :max="6"
                :plus-on-max="true"
                :label-every="1"
                @prev="prevSlide"
                @next="nextSlide"
              >
                <template v-slot:icon>
                  <RoomIcon/>
                </template>
              </RangeSlideWrapper>
            </div>

            <!-- Mobile Quick Selection -->
            <div class="mobile-only">
              <div class="quick-select">
                <button
                  v-for="value in quickSelectValues"
                  :key="value"
                  class="quick-select-btn"
                  :class="{ active: rooms === value }"
                  @click="selectValue(value)"
                >
                  {{ value }}
                </button>
                <button
                  class="quick-select-btn"
                  :class="{ active: rooms >= 6 }"
                  @click="selectValue(6)"
                >
                  6+
                </button>
              </div>
            </div>
          </div>

          <!-- Help Text -->
          <div class="help-text">
            <i class="far fa-info-circle"></i>
            <span>Zählen Sie alle Wohnräume außer Küche, Bad und Flur.</span>
          </div>
        </div>

        <!-- Footer Section -->
        <div class="footer-section">
          <div class="navigation-buttons">
            <button class="nav-button prev-button" @click="prevSlide">
              <i class="button-icon fas fa-arrow-left"></i>
            </button>
            <button class="nav-button next-button" @click="nextSlide">
              <i class="button-icon fas fa-arrow-right"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RoomIcon from '@/assets/icons/room.svg'
import RangeSlideWrapper from '@/components/RangeSlideWrapper'
import slideMixin from '@/mixins/slideMixin'

export default {
  name: 'RoomCountSlide',
  mixins: [slideMixin],
  components: {
    RoomIcon,
    RangeSlideWrapper
  },
  props: {
    title: String
  },
  data () {
    return {
      quickSelectValues: [1, 2, 3, 4, 5]
    }
  },
  computed: {
    isTransitioning () {
      return this.$store.state.isTransitioning
    },
    rooms: {
      get () {
        return this.$store.state.collectedData.rooms || 3
      },
      set (value) {
        this.updateValue(value)
      }
    }
  },
  methods: {
    updateValue (value = this.rooms) {
      this.$store.commit('updateCollectedData', { rooms: value })
    },
    selectValue (value) {
      this.rooms = value
    },
    prevSlide () {
      this.updateValue()
      this.$store.commit('prevSlide')
    },
    nextSlide () {
      this.updateValue()
      this.$store.commit('nextSlide')
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/css/_layout.scss';

.room-count-slide {
  .header-section {
    text-align: center;
    margin-bottom: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;

    .slide-title {
      font-size: clamp(1.5rem, 4vw, 2rem);
      color: var(--primary-color);
      font-weight: 600;
      line-height: 1.3;
    }

    .room-badge {
      display: inline-flex;
      align-items: center;
      padding: 0.75rem 1.5rem;
      background: rgba(23, 181, 160, 0.1);
      border-radius: 100px;
      gap: 0.75rem;

      .room-icon {
        width: 24px;
        height: 24px;
        color: var(--secondary-color);
      }

      .current-value {
        font-size: 1.25rem;
        font-weight: 600;
        color: var(--secondary-color);
      }
    }
  }

  .selection-section {
    margin-bottom: 2rem;
  }

  .quick-select {
    display: flex;
    flex-wrap: wrap;
    gap: 0.75rem;
    justify-content: center;
    margin: 1.5rem 0;

    .quick-select-btn {
      min-width: 48px;
      height: 48px;
      padding: 0;
      border: 2px solid #e0e0e0;
      border-radius: 8px;
      background: white;
      color: #666;
      font-size: 1.1rem;
      font-weight: 500;
      cursor: pointer;
      transition: all 0.3s ease;
      display: flex;
      align-items: center;
      justify-content: center;

      &:hover {
        border-color: var(--secondary-color);
        color: var(--secondary-color);
        transform: translateY(-2px);
      }

      &.active {
        background: var(--secondary-color);
        border-color: var(--secondary-color);
        color: white;
      }
    }
  }

  .help-text {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    color: #666;
    font-size: 0.9rem;
    padding: 1rem;
    background: #f8fafc;
    border-radius: 12px;
    margin-top: 1rem;

    i {
      color: var(--secondary-color);
      font-size: 1.1rem;
    }
  }

  @media (max-width: 768px) {
    .quick-select {
      .quick-select-btn {
        flex: 1;
        min-width: calc(33.33% - 0.75rem);
      }
    }

    .navigation-buttons {
      flex-direction: column-reverse;

      .nav-button {
        width: 100%;
        justify-content: center;
      }
    }
  }
}
</style>
