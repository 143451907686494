import Vue from 'vue'
import App from './App.vue'
import store from './store'
import VueAwesomeSwiper from 'vue-awesome-swiper'
import VueSlider from 'vue-slider-component'
import * as VueGoogleMaps from 'vue2-google-maps'
import VModal from 'vue-js-modal'
import SettingsPlugin from '@/settingsPlugin'
import commons from '@/commons'

// require styles
import 'swiper/dist/css/swiper.css'
import 'vue-slider-component/theme/default.css'
import '@/assets/css/hover.min.css'
import '@/assets/css/flexboxgrid.min.css'

// Font Awesome Kit Integration
const fontAwesomeScript = document.createElement('script')
fontAwesomeScript.setAttribute('src', 'https://kit.fontawesome.com/ba2cff48af.js')
fontAwesomeScript.setAttribute('crossorigin', 'anonymous')
document.head.appendChild(fontAwesomeScript)

Vue.config.productionTip = false

Vue.component('VueSlider', VueSlider)
Vue.use(VueAwesomeSwiper)
Vue.use(VueGoogleMaps, {
  load: {
    key: window.prt.settings.googleMapsApiKey,
    libraries: 'places'
  }
})
Vue.use(VModal, { dialog: true })
Vue.use(SettingsPlugin, { debug: false }) // TODO: turn off debug
Vue.prototype.$commons = commons

new Vue({
  store,
  render: h => h(App)
}).$mount('#app')

window.addEventListener('load', () => {
  if (window.parentIFrame) {
    window.parentIFrame.autoResize(true)
  }
})

// Debounce Resize
let resizeTimeout
window.addEventListener('resize', () => {
  clearTimeout(resizeTimeout)
  resizeTimeout = setTimeout(() => {
    if (window.parentIFrame) {
      window.parentIFrame.autoResize(true)
    }
  }, 250)
})

store.subscribe((mutation, state) => {
  if (mutation.type === 'nextSlide' || mutation.type === 'prevSlide') {
    setTimeout(() => {
      if (window.parentIFrame) {
        window.parentIFrame.resize()

        // Sende Message an Parent um Position zu erhalten
        window.parentIFrame.sendMessage('getPosition')
      }
    }, 150)
  }
})
