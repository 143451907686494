/* eslint-disable no-undef */
/* eslint-disable no-console */
import { cloudApiBaseUrl, cloudApiDontTrack, cloudApiIframeKey } from '@/constants'

const prtAnalytics = async step => {
  // await commons.determinePrtIpAddress() // just refresh prtIpAddress cookie if not present
  const body = new URLSearchParams()
  const urlParams = new URLSearchParams(window.location.search)
  body.append('key', cloudApiIframeKey)
  body.append('step', step)
  body.append('utm_source', urlParams.get('utm_source'))
  body.append('utm_medium', urlParams.get('utm_medium'))
  body.append('utm_campaign', urlParams.get('utm_campaign'))
  body.append('utm_term', urlParams.get('utm_term'))
  body.append('utm_content', urlParams.get('utm_content'))

  fetch(cloudApiBaseUrl + '/iframe/createStatistic', {
    credentials: 'include',
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    body: body
  }).catch(err => {
    console.error(err)
  })
}

const trackStep = step => {
  if (cloudApiDontTrack === '1') return
  console.debug(step)
  if (typeof ga !== 'undefined') ga('send', 'event', 'PRTStep', 'click', step)
  if (typeof fbq !== 'undefined') fbq('trackCustom', 'PRTStep', { action: 'click', label: step })
  if (typeof dataLayer !== 'undefined') {
    dataLayer.push({
      'event': 'prt_step',
      'action': 'click',
      'label': step
    })
  }
  prtAnalytics(step)
}

const trackFinished = leadId => {
  if (cloudApiDontTrack === '1') return
  console.debug(leadId)
  if (typeof dataLayer !== 'undefined') {
    dataLayer.push({
      'event': 'generate_lead',
      'value': '50',
      'currency': 'EUR',
      'transaction_id': leadId
    })
  }
  if (typeof ga !== 'undefined') {
    ga('send', 'event', 'generate_lead', 'click', leadId)
  }
  if (typeof fbq !== 'undefined') {
    fbq('track', 'Lead', {
      value: 50,
      currency: 'EUR',
      external_id: leadId
    })
  }
}

export default {
  trackStep,
  trackFinished
}
